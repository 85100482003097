<section class="d-flex align-items-center position-relative">
   <button *ngIf="['store', 'brand', 'keyword', 'category'].includes(profileType) && col.meta?.type==='trackersAll'"
      [disabled]="!permissions.read_access || !actionData?.attributes?.product_count" class="manage-product dropdown-item px-2 d-block" [ngClass]="{'disabled': !permissions.read_access || !actionData?.attributes?.product_count}" (click)="goToManageProd()"
      placement="{{commonFunctions.rtlStatus ? 'right' : 'left'}}" ngbTooltip="Manage Products"
      tooltipClass="info-custom-tooltip">
      <i class="icon icon-Edit"></i>
   </button>
   <div *ngIf="actionType=='buildActions'" ngbDropdown class="d-inline-block position-static" display="dynamic">
      <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"><span><i class="icon icon-Options"></i></span>
      </span>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
         <ng-container [ngSwitch]="col.meta?.type">
            <ng-container *ngSwitchCase="'productsAll'">
               <button [ngClass]="{'cursor-pointer':permissions.read_access}"
                  [disabled]="!permissionsForDiscover.read_access" class="dropdown-item"
                  (click)="commonFunctions.openDiscover(actionData?.id, true)">
                  <i class="icon icon-Discover"></i>
                  <span translate>Discover</span>
               </button>
               <button [ngClass]="{'cursor-pointer':permissions.write_access}" [disabled]="!permissions.write_access"
                  class="dropdown-item" (click)="openNotification()">
                  <i class="icon icon-Notification-on"></i>
                  <span translate>Create Notification</span>
               </button>
               <button *ngIf="actionData.attributes.edit_details?.is_editable;else addProfile"
                  [disabled]="!permissions.write_access" class="dropdown-item"
                  (click)="editProfileRightPanel(actionData.attributes.edit_details.profile_id)">
                  <i class="icon icon-Edit-alt"></i>
                  <span translate>Update Tracker</span>
               </button>
               <ng-template #addProfile>
               <button [disabled]="!permissions.write_access" class="dropdown-item" (click)="addNewProfile('product')">
                     <i class="icon icon-Location-plus"></i>
                     <span translate>Add {{profileType|titlecase}} Tracker</span>
                  </button>
               </ng-template>
               <button [disabled]="!permissions.write_access" class="dropdown-item"
                  (click)="confirmDiscard(discardProduct)">
                  <i class="icon icon-Trash-alt"></i>
                  <span translate>Discard Product</span>
               </button>
            </ng-container>
            <ng-container *ngSwitchCase="'storesAll'">
               <!-- <button [disabled]="!permissions.read_access" *ngIf="actionData.attributes.edit_details.is_editable" class="dropdown-item"
                  (click)="goToManageProd()">
                  <i class="icon icon-Edit"></i>
                  <span translate>Manage Products</span>
               </button> -->
               <button [ngClass]="{'cursor-pointer':permissions.write_access}" [disabled]="!permissions.write_access"
                  class="dropdown-item" (click)="openNotification()">
                  <i class="icon icon-Notification-on"></i>
                  <span translate>Create Notification</span>
               </button>
               <button [disabled]="!permissions.write_access" class="dropdown-item"
                  (click)="openStoreSegmentSelectionModal()">
                  <i class="icon icon-Chart-pie-alt"></i>
                  <span translate>Update Segment</span>
               </button>
               <button *ngIf="actionData.attributes.edit_details?.is_editable;else addProfile"
                  [disabled]="!permissions.write_access" class="dropdown-item"
                  (click)="editProfileRightPanel(actionData.attributes.edit_details.profile_id)">
                  <i class="icon icon-Edit-alt"></i>
                  <span translate>Update Tracker</span>
               </button>
               <ng-template #addProfile>
               <button [disabled]="!permissions.write_access" class="dropdown-item" (click)="addNewProfile('store')">
                     <i class="icon icon-Location-plus"></i>
                     <span translate>Add {{profileType|titlecase}} Tracker</span>
                  </button>
               </ng-template>
            </ng-container>
            <ng-container *ngSwitchDefault>
               <!-- <button *ngIf="['store', 'brand', 'keyword', 'category'].includes(profileType)"
                  [disabled]="!permissions.read_access" class="dropdown-item" (click)="goToManageProd()">
                  <i class="icon icon-Edit"></i>
                  <span translate>Manage Products</span>
               </button> -->
               <button *ngIf="[].includes(profileType)" [ngClass]="{'cursor-pointer':permissions.write_access}"
                  [disabled]="!permissions.write_access" class="dropdown-item" (click)="openNotification()">
                  <i class="icon icon-Notification-on"></i>
                  <span translate>Create Notification</span>
               </button>
               <button [disabled]="!permissions.write_access" class="dropdown-item" (click)="editProfileRightPanel()">
                  <i class="icon icon-Edit-alt"></i>
                  <span translate>Update Tracker</span>
               </button>
               <button [disabled]="!permissions.write_access" class="dropdown-item"
                  (click)="openModal(content,'deleteView')">
                  <i class="icon icon-Trash"></i>
                  <span translate>Delete Tracker</span>
               </button>
               <!-- <button *ngIf="profileType==='store'" [disabled]="!permissions.write_access" class="dropdown-item"
                  (click)="openStoreSegmentSelectionModal()">
                  <i class="icon icon-Chart-pie-alt"></i>
                  <span translate>Update Segment</span>
               </button> -->
               <button [disabled]="!permissions.write_access" class="dropdown-item"
                  (click)="openModal(content1,'statusView')"><i
                     class="{{actionData?.attributes?.is_active?'icon icon-Pause':'icon icon-Play'}}"></i>
                  <span translate>{{this.actionData?.attributes?.is_active ? 'Pause Tracker' : 'Resume
                     Tracking'}}</span></button>
            </ng-container>
         </ng-container>
      </div>
   </div>
</section>

<div *ngIf="(actionType=='mappedProfiles')" class="mapped-profile">
   <div class="mapped-profile-count" (click)="openModal(content,'deleteView')"
      *ngIf="actionData?.attributes?.profile_count">
      {{actionData?.attributes?.profile_count}} {{'Profiles' | translate}}
   </div>
   <div class="mapped-profile-count-empty" *ngIf="!actionData?.attributes?.profile_count">
      -
   </div>
</div>

<ng-template #content let-modal>
   <div class="modal-header" *ngIf="actionType=='buildActions'">
      <h4 class="modal-title" translate>Delete Tracker</h4>
   </div>
   <div class="modal-body">
      <i *ngIf="actionType=='buildActions'" class="icon icon-Trash" ngbAutofocus></i>
      <div class="body-content" *ngIf="(actionType=='buildActions')">
         <p translate>Are you sure you want to delete this tracker?</p>
      </div>
   </div>
   <div class="modal-footer">
      <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')" translate> Cancel
      </button>
      <button *ngIf="actionType=='buildActions'" type="button" class="btn btn-primary btn-lg"
         (click)="[deleteProfile(), modal.close('Close click')]" translate>Yes, Delete </button>
   </div>
</ng-template>

<ng-template #content1 let-modal>
   <div class="modal-header">
      <h4 class="modal-title" translate>{{actionData?.attributes?.is_active?"Pause Tracker":"Resume Tracker"}}</h4>
   </div>
   <div class="modal-body">
      <i class="{{this.actionData?.attributes?.is_active?'icon icon-Pause':'icon icon-Play'}}" ngbAutofocus></i>
      <p translate><span translate>Are you sure you want to {{actionData?.attributes?.is_active ? 'pause' : 'resume'}}
            tracking for</span><span> {{actionData?.attributes?.name}}</span> ?</p>
   </div>
   <div class="modal-footer">
      <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')"
         translate>Cancel</button>
      <button type="button" class="btn btn-primary btn-lg" (click)="[deactivateProfile(), modal.close('Close click')]"
         translate>Yes</button>
   </div>
</ng-template>

<ng-template #discardProduct let-modal>
   <div class="modal-header">
      <h4 class="modal-title" translate>Discard Product</h4>
   </div>
   <div class="modal-body">
      <i class="icon-Trash-alt" ngbAutofocus></i>
      <p translate><span translate>Are you sure you want to discard this product from all trackers?</span></p>
   </div>
   <div class="modal-footer">
      <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')"
         translate>Cancel</button>
      <button type="button" class="btn btn-primary btn-lg" (click)="[discardFromAllTrackers()]"
         translate>Yes</button>
   </div>
</ng-template>