import { Component, OnInit } from '@angular/core';
import { LocalStorageService} from '@ds-common-services/storage-services/local-storage.service' 
import { HttpRequestService} from '@ds-common-services/http-services/http-request.service' 
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@ds-common-services/utility-services/notification.service';
import { DataTransferService } from '@ds-common-services/utility-services/data-transfer.service';
import { Subject } from 'rxjs';
import { AccessMatrixService } from '@ds-common-services/utility-services/access-matrix.service';
import { MixpanelService } from '@ds-common-services/utility-services/mixpanel.service';
import { AccessMatrix } from '@ds-enums/access-matrix.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonFunctionsService } from '@ds-common-services/utility-services/common-functions.service';
import { map, takeUntil } from 'rxjs/operators';
import { GlobalService } from '@ds-common-services/http-services/global.service';
import { customerList } from './Config';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public submitted: boolean = false;
  public showPassword: boolean = true;
  public language: any;
  public isLoading: boolean = false;
  private destroy$: Subject<boolean> = new Subject();
  constructor(private localStorageService:LocalStorageService,
    private cHttp:HttpRequestService, private notificationService: NotificationService, private dataTransferService: DataTransferService, private accessMatrixService: AccessMatrixService, private modalService: NgbModal, private commonFunctions: CommonFunctionsService,
     private globalService: GlobalService, private mixpanelService: MixpanelService, private router: Router) { }

  ngOnInit(): void {
    this.modalService.dismissAll();
    this.language = this.commonFunctions.getLang();
    this.localStorageService.clearAll();
    this.loginForm = new UntypedFormGroup({
      'email_id': new UntypedFormControl(null, Validators.required),
      'password': new UntypedFormControl(null, Validators.required),
    });
    this.commonFunctions.setLang(this.language);
  }

  loginPromise(loginCred){
    return new Promise((resolve,reject)=>{
      this.isLoading = true;
    this.globalService.checkAuthorization(loginCred).pipe(takeUntil(this.destroy$)).subscribe((res:any) => {
      if(res){
        this.localStorageService.set('jwt', res['data'].access);
        resolve(res)
      }
    }, (err: any) => {
      if (err.error.summary === 'Password is incorrect') {
        this.loginForm.controls['password'].setErrors({ 'passwordError': 'Password is incorrect' });
      }
      else if (err.error.summary === 'No active account found with the given credentials') {
        this.loginForm.controls['email_id'].setErrors({ 'emailError': 'No active account found with the given credentials' });
      }
      else if (err.error.summary === 'User is disabled') {
        this.loginForm.controls['email_id'].setErrors({ 'emailError': 'User is disabled' });
      }
      else if (err.error.summary === 'Email or password is incorrect') {
        this.notificationService.setMessage(
          err.error.context_code,
          err.error.summary
        );
      }
      else if (err.error.summary === 'Request limit exceeded.') {
        this.notificationService.setMessage(
          1100,
          `Request limit exceeded. Please try again after ${Math.ceil(err.error.data.try_after/60)} minutes.`
        );
      }
      reject()
    });
    })
  }

  onSubmit(){
    this.submitted = true;
    if (this.loginForm.status == "VALID") {
      this.authorize(this.loginForm.get('email_id').value,this.loginForm.get('password').value);
    }
  }
   showLoginPassword(){
     this.showPassword = !this.showPassword;
   }

  authorize(emailId,password){
    this.isLoading = true;
    const loginCred = {
      "email": emailId,
      "password": password
    }
    this.loginPromise(loginCred).then((loginResponse)=>{
      this.globalService.getUserInfo().pipe(takeUntil(this.destroy$)).subscribe((userInfo)=>{
        this.getMlServicesList();
        this.commonFunctions.setLang(this.language);
        this.localStorageService.set(this.localStorageService.currencyListStorageKey, this.commonFunctions.getEncodedData(JSON.stringify(loginResponse['data'].currency)));
        this.commonFunctions.currencySelected = loginResponse['data'].currency;
        this.getUserRoleInfo(loginResponse, userInfo);
        this.setUserData(userInfo)
        this.isLoading=false
      })

    }).catch((err)=>{
      this.isLoading=false
    })
  }

  private setUserData(response) {
    this.mixpanelService.init(response.data.id, response.data);
    //Hide submenus for a specific Customer
    customerList[response.data.customer.attributes.name.toLocaleLowerCase()]?.forEach((subMenus: string) => this.commonFunctions.hideSubmenus[subMenus] = true); 
    response.data = {...response.data,'hideSubmenus': this.commonFunctions.hideSubmenus};
    response = {...response, 'data':response.data}
    this.mixpanelService.track('Login action', {});
		this.localStorageService.set('user', this.commonFunctions.getEncodedData(JSON.stringify(response)))
    this.dataTransferService.sendSignalAfterSuccessLogin(true);
	  }

  private getUserRoleInfo(loginDetails, userInfo){
    userInfo.data.role.modules.forEach((item)=>{
      item['path']=AccessMatrix[item.module_name]
    })
      this.accessMatrixService.userRoles = userInfo.data.role.modules;
      this.accessMatrixService.navigateAfterLogin();
      this.dataTransferService.sendPreloadingStrategyData(true);
      this.notificationService.setMessage(loginDetails["context_code"], this.commonFunctions.getSpecificTranslation('Welcome to Anchanto Digital Shelf')+`, ${loginDetails.data.username}!`);
  }

  getMlServicesList(){
    this.globalService.getMLServiceList().pipe(map((res)=>{
      const codeToSortId = {
        'pm': 1,
        'cq': 2,
        'sa': 3
      };
      res['data'] = res['data'].map((item)=>{
        item['sortId'] = codeToSortId[item.code];
        return item
      }).sort((a,b)=>a.sortId - b.sortId) || []
      return res;
    })).subscribe((res) => {
      if (res) {
        this.localStorageService.set(this.localStorageService.mlServicesStorageKey, this.commonFunctions.getEncodedData(JSON.stringify(res['data'])));
      }
    },(err)=>{
      this.localStorageService.set(this.localStorageService.mlServicesStorageKey, this.commonFunctions.getEncodedData(JSON.stringify([])))
    })
  }

  onForgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete()
  }
}