import { Injectable } from '@angular/core';
import { HttpRequestService } from '@ds-shared/common-services/http-services/http-request.service';
import { Resource, Service } from 'ngx-jsonapi';
import { forkJoin } from 'rxjs';

export class Harmonised extends Resource {
	public attributes = {
		type: "",
		id: ""
	};
}

@Injectable({
	providedIn: "root"
})
export class HarmonisedListService extends Service<Harmonised> {
	public resource = Harmonised;
    public type = '';
	public ttl = 1;
	public setType(val: string) {
		this.type = val;
	  }
}


@Injectable({
  providedIn: 'root'
})
export class HarmonisationService extends Service<Harmonised> {

	constructor(public http: HttpRequestService) {
		super();
	}


	public addHarmonisation(type: string, body:{}){
		return this.http.callPostApi(`harmonisation/harmonisation-by-type?filter[harmonisation_type]=${type}`,body)
	}

	public editHarmonisation(type: string, id: number, body:{}){
		return this.http.callPutApi(`harmonisation/harmonisation-by-type?filter[harmonisation_type]=${type}&id=${id}`,body)
	}

	public deleteHarmonisation(type: string, id: number){
		return this.http.callDeleteApi(`harmonisation/harmonisation-by-type?filter[harmonisation_type]=${type}&id=${id}`)
	}
	
	public deleteHarmonisationMapping(payload:{}){
		return this.http.callPutApi(`harmonisation/harmonised-mapping`, payload)
	}

	public addMapping(payload:{}){
		return this.http.callPostApi(`harmonisation/harmonised-mapping`, payload)
	}

	public getRawEntity(payload){
		return this.http.callGetApi(`harmonisation/raw-entity?filter[harmonisation_type]=${payload.harmonisation_type}${payload.page ? `&page[number]=${payload.page}` : ''}&page[size]=${payload.size}${payload.search ? `&filter[search]=${payload.search}` : ''}${payload.status ? `&filter[status]=${payload.status}` : ''}${payload.currency_id ? `&filter[currency_id]=${payload.currency_id}` : ''}`)
	}

	public getHarmonisationEntity(payload){
		return this.http.callGetApi(`harmonisation/harmonisation-by-type?filter[harmonisation_type]=${payload.harmonisation_type}${payload.page ? `&page[number]=${payload.page}` : ''}&page[size]=${payload.size}${payload.search ? `&filter[search]=${payload.search}` : ''}${payload.currency_id ? `&filter[currency_id]=${payload.currency_id}` : ''}`)
	}

	public getVariantList(parent_product_id: number, is_active: boolean, harmonised_id?:number) {
		return this.http.callGetApi(`scorecard/get-variant-list?filter[parent_product_id]=${parent_product_id}&filter[is_active]=${is_active}${harmonised_id ? `&filter[harmonised_id]=${harmonised_id}`:''}`)
	}

	udpateVariantMapping(map_payload, remove_payload) {
		let apis = []
		if (map_payload) apis.push(this.http.callPostApi(`harmonisation/harmonised-mapping`, map_payload));
		if (remove_payload) apis.push(this.http.callPutApi(`harmonisation/harmonised-mapping`, remove_payload));
		return forkJoin(apis);
	}
}
