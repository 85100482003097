/* eslint-disable prettier/prettier */
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeFormatePipe } from '@ds-pipes/date-time-format.pipe';
import { Modules } from '@ds-enums/modules.enum';
import { RedirectionMemoryModel } from '@ds-models/redirection-memory.model';
import { LocalStorageService } from '@ds-common-services/storage-services/local-storage.service';
import { DataTransferService } from './data-transfer.service';
import { ProductSummaryComponent } from '@ds-common-components/scorecard/summary-overlay/product-summary/product-summary.component';
import { DOCUMENT } from '@angular/common';
import { DecimalPipe, TitleCasePipe } from '@angular/common';
import { NumberSuffixPipe } from '@ds-pipes/number-suffix.pipe';
import { PAYLOAD, TIMELINE } from '@ds-shared/enums/common.enum';
import moment from 'moment';
import { element } from 'protractor';

interface ReportingDefaultOptions {
	date: {
		startDate: any,
		endDate: any
	},
	criteria: {
		name: string,
		code: string
	}
}
@Injectable({
	providedIn: "root",
})
export class CommonFunctionsService {
  private readonly REDIRECTION_KEY_NAME = 'urlTracked';
  private readonly FILTER_STORAGE_KEY_NAME = 'filterFeed';
  private isRTL: boolean = false;
  public defaultLanguage: any = { name: "English", code: "en", rtl: false };
  public colorArray: string[] = ['#0389ff', '#ff5800', '#ff8f00', '#feab06', '#ff1948', '#019a2c', '#1b2331', '#67bffb', '#be4201', '#c88601', '#DC3F53', '#0C1B42', '#B62B24', '#E70674', '#1D3B2D', '#6451D3', '#5BBA98', '#74314B', '#F3A32F', '#DF2D54', '#6645FE', '#153B77', '#42046D', '#EF4D07', '#063F51', '#1D8971']; // Total 26 unique colors, dont reduce it.
  public secondaryMenuBlockerRoutes = ['dashboard', 'discover', 'content-quality'];
  public renderer: Renderer2;
  public pdpLimit: number = 1000;
  public currentCustomer;
  public currencySelected;
  public differentBadges: any = {
    OS: { name: "Official", class: 'badge-dark-orange' },
    GS: { name: "Greyseller", class: 'badge-grey' },
    RS: { name: "OARP (Reseller)", class: 'badge-orange' },
    CP: { name: "Competitor", class: 'badge-blue' },
    OT: { name: "Others", class: 'badge-others' },
    self: { name: '', class: 'badge-dark-orange' }
  }
  public notificationCampaignRestrictedKPIList = ['GMV', 'Units Sold', 'Not Selling (Ageing)'];
  public hideSubmenus = {
    '/sales/dealer-analysis': false,
    '/sales/brand-analysis': false,
    '/sales/sku-analysis': false
  }
  public monthlyMapping: any = {
    "M01": "Jan",
    "M02": "Feb",
    "M03": "Mar",
    "M04": "Apr",
    "M05": "May",
    "M06": "Jun",
    "M07": "Jul",
    "M08": "Aug",
    "M09": "Sep",
    "M10": "Oct",
    "M11": "Nov",
    "M12": "Dec",
  }
  constructor(private router: Router, private _decimalPipe: DecimalPipe, private localStorageService: LocalStorageService, private translate: TranslateService, public dataTransferService: DataTransferService, private ngbModalService: NgbModal, @Inject(DOCUMENT) private document: Document, private titlecasePipe: TitleCasePipe) {
    this.currencySelected = this.localStorageService?.get(this.localStorageService.currencyListStorageKey) ? JSON.parse(decodeURIComponent(escape(window.atob(this.localStorageService.get(this.localStorageService.currencyListStorageKey))))) : null;
	if (this.localStorageService.get("user")) {
		this.currentCustomer = this.getUserData()?.data?.customer?.attributes?.name;
	}
  }

  public redirectToScorecardInfo(id: string | number, type: "store" | "product" | "keyword" | "brand" | "category", returnURL = false, view?: string): any {
    if (id === null || id === undefined || !id.toString().trim()) return;
    if (returnURL) {
      return {
        params: `/${(type === 'keyword' || type === 'category') ? 'share-of-search' : 'scorecard'}/${type?.toLocaleLowerCase()}/${this.getEncodedData(id.toString())}`,
        queryParams: { 'view': view }
      }
    }
    this.router.navigate([`/${(type === 'keyword' || type === 'category') ? 'share-of-search' : 'scorecard'}/${type?.toLocaleLowerCase()}/${this.getEncodedData(id.toString())}`]);
  }

  public redirectToHarmonisedView(id: string | number, type: "store" | "product" | "brand" | "category", returnURL = false, view?: string): any {
    if (id === null || id === undefined || !id.toString().trim()) return;
    if (returnURL) {
      return {
        params: `/scorecard/${type?.toLocaleLowerCase()}/${this.getEncodedData(id.toString())}`,
        queryParams: { 'view': view }
      }
    }
    this.router.navigate([`/scorecard/${type?.toLocaleLowerCase()}/${this.getEncodedData(id.toString())}?view=harmonised`]);
  }

	setRedirectionForScorecard() {
		this.setRedirectionMemory(new RedirectionMemoryModel(Modules.scorecard, this.router.url));
	}
	public getEncodedData = (data: any) => window.btoa(unescape(encodeURIComponent(data)));
	public getDecodedData = (data: any) => decodeURIComponent(escape(window.atob(data)));

	public redirectToNotification(notificationType: any, notificationInfo: any, currentRoute?: any, beforePath: any = '', initialParams: any = {}) {
		this.router.navigate([`${beforePath}add-notification`], {
			relativeTo: currentRoute,
			queryParams: {
				...initialParams,
				notificationType: notificationType,
				notificationInfo: notificationInfo,
			},
			queryParamsHandling: "merge",
		});
	}

	public redirectToProfile(profileType: any, profileInfo: any, currentRoute?: any, beforePath: any = '', initialParams: any = {}) {
		this.router.navigate([`${beforePath}add-profile`], {
			relativeTo: currentRoute,
			queryParams: {
				...initialParams,
				p: profileType,
				profileInfo: profileInfo,
			},
			queryParamsHandling: "merge",
		});
	}

	public encodeURi(item: string) {
		try {
			return encodeURIComponent(item);
		} catch {
			return item;
		}
	}
	getStarStatus(starValue: string | number): string {
		if (parseFloat(starValue?.toString()) < 3) return 'danger';
		else if (parseFloat(starValue?.toString()) >= 3 && parseFloat(starValue.toString()) < 4) return 'warning';
		else return 'success';
	}

	/* ************* REDIRECTION MEMORY FUNCTIONALITY START **************** */
	setRedirectionMemory(data: RedirectionMemoryModel) {
		const checkSession = this.getRedirectionMemory('ALL') || [];
		checkSession.push(data)
		this.localStorageService.sessionStorageSet(this.REDIRECTION_KEY_NAME, checkSession)
	}

	getRedirectionMemory(module?) {
		const data = this.localStorageService.sessionStorageGet(this.REDIRECTION_KEY_NAME) || [];
		if (module == 'ALL') {
			return data;
		} else {
			const moduleName = this.getModuleName();
			try {
				return data.find((item) => item.toModule == moduleName);
			} catch {
				return null;
			}
		}
	}

	// clearRedirectionMemoryByModule(){

	// }

	clearRedirectionMemory() {
		this.localStorageService.removeSessionItem(this.REDIRECTION_KEY_NAME);
	}

	getModuleName() {
		return Modules[location.href.split('//')[1].split('/')[1]];
	}

	/* ************* REDIRECTION MEMORY FUNCTIONALITY END **************** */

	/* ************* FILTER STORAGE START **************** */
	setFilterFeed(filter, module?, path = this.router.url, isMultipleFilter = false) {
		const filterData = {
			module: module || this.getModuleName(),
			path: path?.split('?')[0],
			isMultipleFilter,
			filter
		}
		const existingFilters = this.getFilterFeed('all') || [];
		let idx: number;
		if (isMultipleFilter) {
			idx = existingFilters.findIndex(item => (item.path === this.router.url?.split('?')[0]));
		} else {
			idx = existingFilters.findIndex(item => (item.module === filterData.module));
		}

		if (idx !== -1) {
			existingFilters[idx] = filterData;
		} else {
			existingFilters.push(filterData);
		}
		this.localStorageService.sessionStorageSet(this.FILTER_STORAGE_KEY_NAME, existingFilters)
	}

	getFilterFeed(module?) {
		const filters = this.localStorageService.sessionStorageGet(this.FILTER_STORAGE_KEY_NAME);
		if (module === 'all') {
			return filters;
		} else {
			const currentModuleName = module || this.getModuleName()
			try {
				return filters.find(item => item.module === currentModuleName)?.filter;
			} catch {
				return null
			}
		}
	}

	getFilterFeedByPath() {
		const filters = this.localStorageService.sessionStorageGet(this.FILTER_STORAGE_KEY_NAME);
		const currentURL = this.router.url.split('?')[0]
		try {
			return filters.find(item => item.path === currentURL)?.filter;
		} catch {
			return null
		}
	}

	removeFilterDataByPath() {
		let filters = this.localStorageService.sessionStorageGet(this.FILTER_STORAGE_KEY_NAME);
		if (filters && filters.length) {
			filters = filters.filter((item) => item.path !== this.router.url.split('?')[0])
		}
		this.localStorageService.sessionStorageSet(this.FILTER_STORAGE_KEY_NAME, filters)
	}

	clearFilterFeed() {
		this.localStorageService.removeSessionItem(this.FILTER_STORAGE_KEY_NAME);
	}
	/* ************* FILTER STORAGE END **************** */

	formatSimpleDate(date, format?): string | void {
		if (date) {
			try {
				return DateTimeFormatePipe.prototype.transform(new Date(date), format);
			} catch {
				return date
			}
		}
		return date;
	}
	getAbsoluteValue(val: any): any {
		return Math.abs(val);
	}

  checkClickInOrOutComponent(event, componentName) {
    const paths = event['path'] as Array<any>;
    let inComponent = false;
    paths.forEach(path => {
      if (path.tagName != undefined) {
        const tagName = path.tagName.toString().toLowerCase();
        if (tagName == componentName)
          inComponent = true;
      }
    });
    return inComponent;
  }
  getColumnList(filterArray: any[], type: string, key: string) {
    let counter = 0;
    return filterArray.reduce((filterRes: any, item: any, index: any) => {
      if (item?.isChecked && item[key]) {
        const value = item[key].split(".")[item[key].split(".").length - 1];
        if(value === 'store' || value === 'store_name' || (item.name === 'Store' || item.name === 'Store Name')){
          filterRes = filterRes + `&filter[${type}][${counter}]=${value}`;
          counter += 1;
          filterRes = filterRes + `&filter[${type}][${counter}]=segment`;
          counter += 1;
          return filterRes
        }
        else if((value === 'price_new'  && !item.meta?.change) || (value === 'price' && !item.meta?.change) || value === 'current_price'){
          filterRes = filterRes + `&filter[${type}][${counter}]=${value}`;
          counter += 1;
          filterRes = filterRes + `&filter[${type}][${counter}]=discount_new`;
          counter += 1;
          return filterRes
        }
        else{
          filterRes = filterRes + `&filter[${type}][${counter}]=${value}`;
          counter += 1;
          return filterRes
        }
      }
      return filterRes
    }, '');
  }

	getFilterData(filterArray: any, type: any, filterName, key?: any) {
		let level_1_index = -1;
		let level_2_index = -1;
		let level_3_index = -1;
		return filterArray?.reduce((filterRes: any, item: any, index: any) => {
			const value = key !== null ? this.getMappedObject(item, key) : item;
			if (type === 'categories') {
				if (filterName === 'category_l1') {
					level_1_index++;
					return filterRes + `&filter[${type}_l1][${level_1_index}]=${this.encodeURi(item.attributes.name)}`
				}
				else if (filterName === 'category_l2') {
					level_2_index++;
					return filterRes + `&filter[${type}_l2][${level_2_index}]=${this.encodeURi(item.attributes.name)}`
				}
				else {
					level_3_index++;
					return filterRes + `&filter[${type}][${level_3_index}]=${this.encodeURi(item.attributes.name)}`
				}
			}
			else return filterRes + `&filter[${type}][${index}]=${(typeof value === 'string') ? this.encodeURi(value) : value}`
		}, '');
	}
	getMappedData(filterArray: any, key?: any) {
		const temp = key?.split('.')
		return filterArray?.map((item: any) => {
			return temp?.reduce((res: any, level: any, index: number) => {
				return (typeof res?.[level] === 'string') ? this.encodeURi(res?.[level]) : res?.[level];
			}, item)
		})
	}
	getMappedObject(currectObject: any, key?: any) {
		const temp = key?.split('.');
		return temp?.reduce((res, item) => {
			return res?.[item]
		}, currectObject);
	}

	/* ************* File to Base64 START **************** */
	getBase64(file): Promise<string | {}> {
		return new Promise((res, rej) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				res(reader.result)
			};
			reader.onerror = function (error) {
				rej(error)
			};
		})
	}
	/* ************* File to Base64 END **************** */

	/* ************* RERENDER CHILD COMPONENTS START **************** */

	reRender() {
		const path = this.router.url;
		this.router.navigateByUrl('/user-management', { skipLocationChange: true }).then(() => {
			this.router.navigate([path]);
		})
	}

	/* ************* RERENDER CHILD COMPONENTS END **************** */


	/* ************* LOCALIZATION SETTINGS START ************* */
	set rtlStatus(rtlStatus: boolean) {
		this.isRTL = rtlStatus;
	}

	get rtlStatus() {
		return this.isRTL;
	}

	getLang = () => {
		try {
			return JSON.parse(this.getDecodedData(localStorage.getItem('lang')));
		}
		catch (e: any) {
			this.setLang(this.defaultLanguage);
			return this.defaultLanguage;
		}
	}
	setLang(langObj: any) {
		localStorage.setItem('lang', this.getEncodedData(JSON.stringify(langObj)));
		this.rtlStatus = langObj.rtl;
		if (langObj?.rtl) document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
		else document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
		this.watchSecondaryMenu();
	}

	getSpecificTranslation(text: string) {
		if (!text?.trim()) return text;
		let translatedText;
		this.translate.get(text).subscribe((translatedData: any) => {
			translatedText = translatedData;
		}).unsubscribe();
		return translatedText;
	}

	/* ************* LOCALIZATION SETTINGS END ************* */

	/* ************* REPLACER IN STRING START ************* */

	public findAndReplaceAll(str = '', find, replace) {
		if (!str?.trim()) return str;
    return str.replace(new RegExp(find, "g"), replace);
  }

  private escapeRegExpression(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }

  getDynamicExportFileName(param, name) {
    let timeline: string;
    if (param) {
      if (param === TIMELINE.CURRENT_MONTH) timeline = `${moment().subtract(30, "d").format("YYYYMMDD")}_${moment().subtract(1, 'd').format("YYYYMMDD")}`;
      else if (param === TIMELINE.PREVIOUS_MONTH) timeline = moment().subtract(60, "d").format("YYYYMMDD") + '_' + moment().subtract(31, "d").format("YYYYMMDD");
      else if (param === TIMELINE.CURRENT_WEEK) timeline = moment().subtract(7, "d").format("YYYYMMDD") + '_' + moment().subtract(1, 'days').format("YYYYMMDD");
      else if (param === TIMELINE.PREVIOUS_WEEK) timeline = moment().subtract(14, "d").format("YYYYMMDD") + '_' + moment().subtract(8, "d").format("YYYYMMDD");
      else if (param === TIMELINE.YESTERDAY) timeline = moment().subtract(1, "d").format("YYYYMMDD");
      else if (param === TIMELINE.TODAY) timeline = moment().format("YYYYMMDD");
      else if (param === TIMELINE.LAST_30_DAYS) timeline = moment().subtract(30, "d").format("YYYYMMDD") + '_' + moment().subtract(1, "d").format("YYYYMMDD");
      else if (param === TIMELINE.LAST_90_DAYS) timeline = moment().subtract(90, "d").format("YYYYMMDD") + '_' + moment().subtract(1, "d").format("YYYYMMDD");
      else if (param === TIMELINE.THIS_MONTH) timeline = moment().startOf("month").format("YYYYMMDD") + '_' + moment().endOf("month").format("YYYYMMDD");
      else if (param === TIMELINE.LAST_60_DAYS) timeline = moment().subtract(59, "days").format("YYYYMMDD") + '_' + moment().format("YYYYMMDD");
      else timeline = param;
      return this.capitalizeWithUnderspace(name) + '_' + timeline;
    }
    else
      return this.capitalizeWithUnderspace(name);

	}

	capitalizeWithUnderscore(str) {
		var i, frags = str.split('_');
		for (i = 0; i < frags.length; i++) {
			frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
		}
		return frags.join('');
	}

	capitalizeWithUnderscorandSpace(str) {
		if (str === 'marketplace') {
			// BE uses marketplace as key
			return 'Retail Channel'
		}
		else {
			if (str === 'gmv') return 'GMV'
			else if (typeof str === 'string') {
				var i, frags = str.split('_');
				for (i = 0; i < frags.length; i++) {
					frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
				}
				return frags.join(' ');
			}
			else {
				return str;
			}
		}
	}


	capitalizeWithUnderspace(str) {
		let i, frags = str.split(' ');
		for (i = 0; i < frags.length; i++) {
			frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
		}
		return frags.join('');
	}

	/* ************* REPLACER IN STRING END ************* */

	getRandomColor() {
		const letters = '0123456789ABCDEF';
		let color = '#';
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	private _defaultOptionsForReporting: ReportingDefaultOptions;
	get defaultOptionsForReporting() {
		return this._defaultOptionsForReporting;
	}
	set defaultOptionsForReporting(data) {
		this._defaultOptionsForReporting = data;
	}

	public getColorFromName(name) {
		const colorData = {
			'Black&Decker': {
				color: '#67bffb'
			},
			'Stanley': {
				color: '#ee6002'
			},
			'Dewalt': {
				color: '#ff5800'
			},
			'Naver': {
				color: '#0389ff'
			},
			'Coupang': {
				color: '#3A00ED'
			},
			'Interpark': {
				color: '#4185F4'
			},
			'11street': {
				color: '#256C84'
			},
			'Auction': {
				color: '#EE6004'
			},
			'Gmarket': {
				color: '#02C51F'
			},
			'E&D': {
				color: '#C0CA33'
			},
			'Bosch': {
				color: '#4EA9D1'
			},
			'Milwaukee': {
				color: '#F1454E'
			}
		}
		return colorData[name] ? colorData[name]['color'] : this.getRandomColor();
	}

	goToProfile(profileDetails, returnURL = false): any {
		if (this.router.url.includes('manage-products') || this.router.url.includes('manage-mappings') || this.router.url.includes('add-notification')) return;
		if (returnURL) {
			return {
				params: `/configuration/profile/${profileDetails.attributes.profile_type}/profile-information`, queryParams: {
					profile: this.getEncodedData(profileDetails?.id),
					c: true,
					name: this.getEncodedData(profileDetails.attributes.name),
					profileType: profileDetails.attributes.profile_type
				}
			}
		}
		this.setRedirectionMemory(new RedirectionMemoryModel(Modules.scorecard, this.router.url));
		this.router.navigate([`/configuration/profile/${profileDetails.attributes.profile_type}/profile-information`], {
			queryParams: {
				profile: this.getEncodedData(profileDetails?.id),
				c: true,
				name: this.getEncodedData(profileDetails.attributes.name),
				profileType: profileDetails.attributes.profile_type
			},
			// queryParamsHandling: "merge",
		});
	}

	public openProductSummaryOverlay(prodId: string | number): void {
		const componentRef = this.ngbModalService.open(ProductSummaryComponent, {
			centered: true
		});
		componentRef.componentInstance.productId = prodId
	}

	randomId(length) {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}
	validateUrl(url: string): boolean {
		const reg = new RegExp(
			"(http(s)?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
		);
		if (reg.test(url)) {
			return true;
		} else return false;
	}
	capitalizeFirstLetter(text): string {
		return text?.charAt(0)?.toUpperCase() + text?.slice(1);
	}
	public toTitleCase(inputText) {
		return inputText.replace(/\b\w/g, function (match) {
			return match.toUpperCase();
		});
	}

	/* ************* SEONDARY MENU CONFIG START ************* */

	readonly PRIMARY_MENU_WIDTH = 15;
	readonly EXPANDED_SECON_MENU_WIDTH = 0;
	readonly COLLAPSED_PRIMARY_MENU_WIDTH = 3.5;

	private isSecondaryMenuExpanded = true;
	set secondaryMenuExpanded(status: boolean) {
		this.isSecondaryMenuExpanded = status;
	}
	get secondaryMenuExpanded() {
		return this.isSecondaryMenuExpanded;
	}

	public toggleSecondaryMenu(callType: string) {
		const parentListEl = document.getElementsByClassName('app-structure')[0];
		//on Click close the primary menu
		if (callType === 'callFromClick') {
			if (!parentListEl.classList.contains('wrapped-menu')) {
				parentListEl.classList.add("wrapped-menu");
				this.secondaryMenuExpanded = false;
			}
			else {
				parentListEl.classList.remove("wrapped-menu");
				this.secondaryMenuExpanded = true;
			}
		}
		else if (callType === 'callFromMenu') {
			if (parentListEl.classList.contains('wrapped-menu')) {
				parentListEl.classList.remove("wrapped-menu");
				this.secondaryMenuExpanded = true;
			}
		}
		this.watchSecondaryMenu();
		this.dataTransferService.sendSecondaryMenuToggleSignal(true);
	}

	public watchSecondaryMenu() {
		const parentListEl = document.getElementsByClassName('app-structure')[0];
		setTimeout(() => {
			parentListEl.classList.contains('wrapped-menu') ? this.removeSecondaryMenuStyles() : this.addSecondaryMenuStyles()
		}, 0);
	}

	private removeSecondaryMenuStyles() {
		const MASTER_CONTENT_WRAPPER = this.document.getElementsByClassName('app-content')[0] as HTMLElement;
		// const GLOBAL_HEADER = this.document.getElementsByClassName('global-header-wrapper')[0] as HTMLElement;
		const CONTENT_WRAPPER = this.document.getElementsByClassName('content-wrapper')[0] as HTMLElement;
		MASTER_CONTENT_WRAPPER?.style.setProperty('width', `calc(100vw - ${this.COLLAPSED_PRIMARY_MENU_WIDTH + 1}rem)`);
		// GLOBAL_HEADER?.style.setProperty(`${document.dir==='ltr'?'left':'right'}`,`${this.PRIMARY_MENU_WIDTH}rem`);
		CONTENT_WRAPPER?.style.setProperty('width', `calc(100vw -  ${this.COLLAPSED_PRIMARY_MENU_WIDTH + 1}rem)`)
	}

	private addSecondaryMenuStyles() {
		const MASTER_CONTENT_WRAPPER = this.document.getElementsByClassName('app-content')[0] as HTMLElement;
		// const GLOBAL_HEADER = this.document.getElementsByClassName('global-header-wrapper')[0] as HTMLElement;
		const CONTENT_WRAPPER = this.document.getElementsByClassName('content-wrapper')[0] as HTMLElement;
		MASTER_CONTENT_WRAPPER?.style.setProperty('width', `calc(100vw - ${this.PRIMARY_MENU_WIDTH}rem)`);
		// GLOBAL_HEADER?.style.setProperty(`${document.dir==='ltr'?'left':'right'}`,`${(this.PRIMARY_MENU_WIDTH+(this.secondaryMenuExpanded?this.EXPANDED_SECONDARY_MENU_WIDTH:this.COLLAPSED_SECONDARY_MENU_WIDTH))}rem`);
		CONTENT_WRAPPER?.style.setProperty('width', `calc(100vw - ${this.PRIMARY_MENU_WIDTH}rem)`);
	}


	/* ************* SEONDARY MENU CONFIG END ************* */

	public replaceNewLineWithBreak(text: string) {
		if (!text?.trim()) return text;
		return text.split('\\\\n').join('<br>').split('\\\n').join('<br>').split('\\n').join('<br>').split('\n').join('<br>').split('\\t').join('\t');
	}

	public extractTextFromHtml(text) {
		if (!text?.trim()) return text;
		const temp = document.createElement('span');
		temp.innerHTML = this.replaceMalnewlineToSingle(text);
		return temp.textContent || temp.innerText || ''
	}

	public replaceMalnewlineToSingle(text: string) {
		if (!text?.trim()) return text;
		return text.split('\\\\n').join('\n').split('\\\n').join('\n').split('\\n').join('\n').split('\\t').join('\t');
	}

	public checkTimeRange(timeline: string) {
		const now = new Date();
		const timerange = {
			startRange: null,
			endRange: null
		}
		if (timeline === TIMELINE.PREVIOUS_MONTH) {
			timerange.startRange = 1
			timerange.endRange = 30
		} else if (timeline === TIMELINE.CURRENT_MONTH) {
			timerange.startRange = 1
			timerange.endRange = 30
		} else if (timeline === TIMELINE.YESTERDAY) {
			timerange.startRange = 0;
			timerange.endRange = 23;
		} else if (timeline === TIMELINE.CURRENT_WEEK) {
			timerange.startRange = 1;
			timerange.endRange = 7;
		} else if (timeline === TIMELINE.PREVIOUS_WEEK) {
			timerange.startRange = 1;
			timerange.endRange = 7;
		} else if (timeline === TIMELINE.TODAY) {
			timerange.startRange = 0;
			timerange.endRange = 23;
		} else if (timeline === TIMELINE.LAST_30_DAYS) {
			timerange.startRange = 1
			timerange.endRange = 30
		} else if (timeline === TIMELINE.LAST_90_DAYS) {
			timerange.startRange = 1
			timerange.endRange = 90
		}
		return timerange;
	}

	getNumberDate(date): any | void {
		let numberDate = date?.format('L').split('/');
		if (numberDate?.length == 3) { return numberDate[2] + '-' + numberDate[0] + '-' + numberDate[1] }
		return;
	}

	//Open Discover - Product Compare
	public openDiscover(product_id: number, newTab: boolean): void {
		if (newTab) window.open(`/discover/product-compare/${product_id}`, "_blank");
		else this.router.navigate([`/discover/product-compare/${product_id}`]);
	}
	//Open Discover - Category Details
	public openDiscoverCategoryDetails(profileId: number, newTab: boolean): void {
		if (newTab) window.open(`/discover/category/${profileId}`, "_blank");
		else this.router.navigate([`/discover/category/${profileId}`]);
	}
	public filterPayload(payload) {
		const temp = payload;
		Object.keys(temp).forEach(item => {
			if ((Array.isArray(temp[item]) && !temp[item]?.length) || (!Array.isArray(temp[item]) && typeof temp[item] !== 'boolean' && !temp[item])) delete temp[item];
		})
		return temp;
	}

	// health-grid 
	public getViewHealthGridColor(kpi, val: number): { 'background-color': string, 'color'?: string } {
		const LOW_PERFORMANCE_KPI = ["Overpriced", "Underpriced", "Not Selling", "Out of Stock", "Low Image Score", "Low Content Score", "Poor Rating", "Poor Sentiment Score"];
		if ((val === null) || (kpi?.isNA && !val)) return { 'background-color': '#F0F3F8', 'color': '#1C2331' };
		if (LOW_PERFORMANCE_KPI.indexOf(kpi.name) > -1) {
			if (val >= 70) return { 'background-color': '#ff194dd9', 'color': '#ffffff' };
			else if (val >= 40 && val < 70) return { 'background-color': '#ff9800d9', 'color': '#ffffff' };
			else return { 'background-color': '#009d2ed9', 'color': '#ffffff' };
		}
		else {
			if (val >= 70) return { 'background-color': '#009d2ed9', 'color': '#ffffff' };
			else if (val >= 40 && val < 70) return { 'background-color': '#ff9800d9', 'color': '#ffffff' };
			else return { 'background-color': '#ff1948d9', 'color': '#ffffff' };
		}
	}

	numberFormat(number: string | number) {
		return this._decimalPipe.transform(+number);
	}

	convertCommaStringToNumber(commaString) {
		const numberValue = parseInt(commaString.replace(/,/g, ''), 10);
		return numberValue;
	}

	getSuffixedNumberFormat(number: string | number, roundOff?: boolean) {
		if (number == null) return '-';
		if (typeof (number) === 'number') return new NumberSuffixPipe().transform(number, 2, roundOff);
		else if (number.includes(",")) return new NumberSuffixPipe().transform(this.convertCommaStringToNumber(number), 2, roundOff);
		else if (number.includes("%")) return number;
		else return new NumberSuffixPipe().transform(Number(number), 2, roundOff);
	}

	// export file param
	public getExportFileParams(moduleFilterParams: any,) {
		let exportParams = {
			currency_id: moduleFilterParams.currency_id,
			marketplace_id: this.getMappedData(
        moduleFilterParams?.marketplace,
        "id"
      ),
			view: moduleFilterParams?.view,
			type: moduleFilterParams?.type,
			start_range: moduleFilterParams?.startRange === 0 ? "0" : moduleFilterParams?.startRange,
      end_range: moduleFilterParams?.endRange === 0 ? "0" : moduleFilterParams?.endRange,
			profiles: moduleFilterParams?.id,
			calc_type: moduleFilterParams?.calc_type,
			start_date: moduleFilterParams.timeline?.startDate,
			end_date: moduleFilterParams.timeline?.endDate,
    }
		if (!moduleFilterParams.showTimeline) exportParams['timeline'] = moduleFilterParams.timeline;
    return this.filterPayload(exportParams);
	}

	public getPageNo(meta, pageSize) {
		if (meta?.next_page === null) {
			if (meta?.total_count % pageSize === 1) {
				if (meta?.prev_page === null)
					return 1
				else return meta?.prev_page
			}
			else return meta?.current_page
		}
		else return meta?.current_page;
	}

	setPositionOfCustomDropdown(event, element, storedEle, raw_data, offset) {
		const raw_list = raw_data;
		if (element.classList.contains('custom-dropdown')) {
			if (event.target.classList.contains("custom-dropdown-item")) {
				window.open(this.redirectToScorecardInfo(raw_list[parseInt(event.target.id)].id, 'store', true)?.params, '_blank');
			}
			else {
				element.classList.remove('custom-dropdown');
				element.removeChild(element.children[1]);
				storedEle = null;
			}
		}
		else {
			if (storedEle) {
				storedEle.classList.remove('custom-dropdown');
				storedEle.removeChild(storedEle.children[1]);
				storedEle = null;
			}
			let raw_ele = ``;
			raw_list.forEach((item, index) => {
				raw_ele = raw_ele + `<div class="dropdown-item custom-dropdown-item" id=${index}>${item.attributes.name}</div>`
			})
			element.classList.add("custom-dropdown");
			const ele = `<div id="custom-dropdown-container" style="display:flex;flex-direction:column;border: 0.063rem solid #e9e9ec;border-radius: 0.12rem;padding: 0.5rem 0.5rem;max-width:400px;max-height:200px;background-color:#ffffff;position:relative;z-index:9999;box-shadow: 0 1.25rem 1.56rem -0.31rem #0000001a, 0 0.62rem 0.62rem -0.31rem #0000000a;overflow: overlay;">${raw_ele}</div>`;
			const p = new DOMParser().parseFromString(ele, 'text/html').body;
			element.appendChild(p);
			storedEle = element;
			const cdc = document.getElementById("custom-dropdown-container");
			let parent_cdc = cdc;
			while (parent_cdc.nodeName !== "HIGHCHARTS-CHART") {
				parent_cdc = parent_cdc.parentElement;
			}
			const parent_bounding = parent_cdc.getBoundingClientRect();
			const cdc_bounding = cdc.getBoundingClientRect();
			const cdc_offset = cdc_bounding.height + cdc_bounding.y;
			const parent_cdc_offset = parent_bounding.height + parent_bounding.y;
			if (parent_cdc_offset - cdc_offset < 0) {
				cdc.style.bottom = `${Math.abs(parent_cdc_offset - cdc_offset) + (cdc_bounding.height - Math.abs(parent_cdc_offset - cdc_offset) + offset)}px`;
				cdc.parentElement.style.background = "transparent";
			}
		}
		return storedEle;
	}
	closeCustomDropdown(event, ele) {
		if (event && ele) {
			let temp = event.target;
			let i = 0;
			while (temp && !temp?.classList.contains("custom-dropdown") && i < 3) {
				temp = temp?.parentElement;
				i++;
			}
			if (!temp.classList.contains("custom-dropdown")) {
				ele.classList.remove('custom-dropdown');
				ele.removeChild(ele.children[1]);
				ele = null;
			}
		}
		else {

		}
		return ele
	}

	public reduceOptionalParams(permittedLocalFilters, localFilters) {
		return permittedLocalFilters.reduce((res: any, item: any) => {
      return res + (localFilters?.[item]?.selected?.length ? this.getFilterData(localFilters?.[item]?.selected, localFilters?.[item]?.path, item, localFilters?.[item]?.meta?.key) : '');
    }, '');
	}

	public makeTitlecase(term: string = ''): string {
		return term ? this.titlecasePipe.transform(term) : '';
	}

	public getFrequencyList(): [] {
		try {
			return this.localStorageService.get(this.localStorageService.frequencyListStorageKey) ? JSON.parse(this.getDecodedData(this.localStorageService.get(this.localStorageService.frequencyListStorageKey))) : null;
		}
		catch {
			return []
		}
	}

	public isCampaignFrequencyAvailable() {
		const frequencyList = this.getFrequencyList();
		return !!frequencyList?.find(item => (item['name'] as string).toLowerCase() === 'campaign')
	}

  public getSavedUserData() {
    try {
      return JSON.parse(this.getDecodedData(this.localStorageService.get(this.localStorageService.userStorageKey)))['data']
    }
    catch {
      return null
    }
  }

  public getCurrentCustomer(){
    const userDetails = this.getSavedUserData();
    if(!userDetails) return null;
    return userDetails.customer.attributes.name as string
  }

  // check if String is Date
  public isDateStringValid(dateString) {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (!datePattern.test(dateString)) {
			return false;
    }
    const date = new Date(dateString);
    return !isNaN(date.getTime());
	}

	public getCategoryLevels(item, localFilters, data) {
		data.gridInfo.params["categories_l1"] = [];
		data.gridInfo.params["categories_l2"] = [];
		data.gridInfo.params["categories"] = [];
		localFilters?.[item]?.selected.forEach((v) => {
			if (item === "category_l1") {
				data.gridInfo.params.categories_l1.push(this.encodeURi(v?.attributes?.name));
			} else if (item === "category_l2") {
				data.gridInfo.params.categories_l2.push(this.encodeURi(v?.attributes?.name));
			}
			else if (item === "category") { data.gridInfo.params.categories.push(this.encodeURi(v?.attributes?.name)); }
		});
		return data.gridInfo.params
	}

	public getCategoryLevelForGrid(param, localFilters, item) {
		param["categories_l1"] = [];
		param["categories_l2"] = [];
		param["categories"] = [];
		localFilters?.[item]?.selected.forEach((v) => {
		  if (item === "category_l1") {
			param.categories_l1.push(this.encodeURi(v?.attributes?.name));
		  } else if (item === "category_l2") {
			param.categories_l2.push(this.encodeURi(v?.attributes?.name));
		  }
		  else if (item === "category") { param.categories.push(this.encodeURi(v?.attributes?.name)); }
		});
		return param;
	  }
	
	  public generateLinearFilter(level, filterList, filter) {
		if (level.name === 2 || level?.isL2Changed) {
		  let filterStr = ''
		  filterList[filter].selected.forEach((v, k) => {
			filterStr = filterStr + `&filter[categories_l1][${k}]=${this.encodeURi(v?.attributes?.name)}`
		  })
		  return filterStr;
		}
		else {
		  let filterStr = ''
		  filterList[filter].selected.forEach((v, k) => {
			filterStr = filterStr + `&filter[categories_l2][${k}]=${this.encodeURi(v?.attributes?.name)}`
		  });
		  if (filterList["category_l1"].selected.length > 0) {
			filterList["category_l1"].selected.forEach((v, k) => {
			  filterStr =
				filterStr +
				`&filter[categories_l1][${k}]=${this.encodeURi(
				  v?.attributes?.name
				)}`;
			});
		  }
		  return filterStr;
		}
	  }

	  public clearScorecardLocalFilters(removeLocalFilter?: boolean) {
		this.localStorageService.removeItem(this.localStorageService.scorecardPage);
		this.localStorageService.removeItem(this.localStorageService.scorecardPageSize);
		this.localStorageService.removeItem(this.localStorageService.scorecardDefaultListType);
		this.localStorageService.removeItem(this.localStorageService.scorecardSearched);
		this.localStorageService.removeItem(this.localStorageService.scorecardSort);
		this.localStorageService.removeItem(this.localStorageService.scorecardCol);
		if (!removeLocalFilter) {
		  this.localStorageService.removeItem(this.localStorageService.scorecardAllLocalFilters);
		  this.localStorageService.removeItem(this.localStorageService.scorecardUserOnlyLocalFilters);
		}
	  }

	public removeSpaceAddUnderscore(name) {
		return name.split(' ').join('_')
	}

	public getMlServiceList(): { name: string, code: string, limit: number }[] {
		try {
		  const mlServiceListData = this.localStorageService.get(this.localStorageService.mlServicesStorageKey);
		  if (mlServiceListData) {
			const decodedData = this.getDecodedData(mlServiceListData);
			return JSON.parse(decodedData);
		  }
		} catch (error) {
		  // Handle any exceptions if needed
		}
	
		return [];
	  }

	public getUserData() {
		const userData = JSON.parse(
			this.getDecodedData(this.localStorageService.get("user"))
		);
		return userData;
	}

	public appendMarketplace(payload) {
		if (payload?.marketplace?.length) {
			return '&' + payload.marketplace.map((item, i) => `filter[${PAYLOAD.MARKETPLACE_ID}][${i}]=${item.id}`).join('&');
		}
		return '';
	}

	getExportDateFormat(date) {
		if (typeof date === 'string') {
			return date;
		}
		if (date?.startDate === date?.endDate) return date?.startDate.split("-").join("");
		return `${date?.startDate.split("-").join("")}_${date?.endDate.split("-").join("")}`;

 }
  
  getPayloadFromObject(payload) {
    let res = ``;
    Object.keys(payload).forEach(item => {
      const data = payload[item];
      if ((!Array.isArray(data))) {
        res = `${res}${item}=${typeof data === 'string' ? this.encodeURi(data?.trim()) : data}&`
      }
      else if ((Array.isArray(data) && data?.length)) {
        res = res + data.reduce((r, i, idx) => {
          return `${r}${item}[${idx}]=${i}&`
        }, "")
      }
    })
    res = res[res.length - 1] === '&' ? res.substring(0, res.length - 1) : res
    return res;
  }

  isIntegerOrDefined(value) {
    if (value !== null && value !== undefined) {
        return typeof value === 'number' && !isNaN(value);
    } else {
        return false; 
    }
}
}




