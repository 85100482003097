import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {

  // global brand
  private globalStoreId: BehaviorSubject<any> = new BehaviorSubject('');
  public globalStoreId$ = this.globalStoreId.asObservable();
  sendGlobalStoreId(data) {
    this.globalStoreId.next(data)
  }
  // global brand ends

  // scorecard dates
  private scorecardDate: BehaviorSubject<any> = new BehaviorSubject('');
  public scorecardDate$ = this.scorecardDate.asObservable();
  sendScorecardDate(data) {
    this.scorecardDate.next(data)
  }
  // scorecard dates end

  // scorecard Product
  private scorecardProductId: BehaviorSubject<any> = new BehaviorSubject('');
  public scorecardProductId$ = this.scorecardProductId.asObservable();
  sendscorecardProductId(data) {
    this.scorecardProductId.next(data)
  }
  // scorecard Product end

  // scorecard Store
  private scorecardStoreId: BehaviorSubject<any> = new BehaviorSubject('');
  public scorecardStoreId$ = this.scorecardStoreId.asObservable();
  sendscorecardStoreId(data) {
    this.scorecardStoreId.next(data)
  }
  // scorecard store end
  // brand scorecard Marketplace start
  private brandScorecardMarketplaceId: BehaviorSubject<any> = new BehaviorSubject('');
  public brandScorecardMarketplaceId$ = this.brandScorecardMarketplaceId.asObservable();
  sendbrandScorecardMarketplaceId(data) {
    this.brandScorecardMarketplaceId.next(data)
  }
  // brand scorecard Marketplace end
  // brand scorecard Store start
  private brandScorecardStoreId: BehaviorSubject<any> = new BehaviorSubject('');
  public brandScorecardStoreId$ = this.brandScorecardStoreId.asObservable();
  sendbrandScorecardStoreId(data) {
    this.brandScorecardStoreId.next(data)
  }

  private advancedSearchStoreId: BehaviorSubject<any> = new BehaviorSubject('');
  public advancedSearchStoreId$ = this.advancedSearchStoreId.asObservable();
  sendadvancedSearchStoreId(data) {
    this.advancedSearchStoreId.next(data)
  }  
  // brand scorecard Store end
  // country start
  private countryId: BehaviorSubject<any> = new BehaviorSubject('');
  public countryId$ = this.countryId.asObservable();
  sendcountryId(data) {
    this.countryId.next(data)
  }
  // country end

  // global brand
  private filterHeightVal: BehaviorSubject<any> = new BehaviorSubject('');
  public filterHeightVal$ = this.filterHeightVal.asObservable();
  filterHeight(data) {
    this.filterHeightVal.next(data)
  }
  // global brand ends

  // left filters
  private leftFilterHeightVal: BehaviorSubject<any> = new BehaviorSubject('');
  public leftFilterHeightVal$ = this.leftFilterHeightVal.asObservable();
  sendLeftFilterHeight(data) {
    this.leftFilterHeightVal.next(data)
  }
  // left filters

  // discover sort height
  private sortHeightVal: BehaviorSubject<any> = new BehaviorSubject('');
  public sortHeightVal$ = this.sortHeightVal.asObservable();
  sendsortHeightVal(data) {
    this.sortHeightVal.next(data)
  }
  // discover sort height ends

  private profileCountVal: BehaviorSubject<any> = new BehaviorSubject('');
  public profileCountVal$ = this.profileCountVal.asObservable();
  sendProfileCountVal(data) {
    this.profileCountVal.next(data)
  }  

    private noteDetails: BehaviorSubject<any> = new BehaviorSubject(null);
    public noteDetails$ = this.noteDetails.asObservable();
    sendnoteDetails(data) {
      this.noteDetails.next(data)
    }

  private isCatalogueModalOpen: BehaviorSubject<any> = new BehaviorSubject('');
  public isCatalogueModalOpen$ = this.isCatalogueModalOpen.asObservable();
  sendisCatalogueModalOpen(data) {
    this.isCatalogueModalOpen.next(data)
  } 
  private defaultStore: BehaviorSubject<any> = new BehaviorSubject('');
  public defaultStore$ = this.defaultStore.asObservable();
  sendDefaultStore(data) {
    this.defaultStore.next(data)
  } 
  private resetProductField: BehaviorSubject<any> = new BehaviorSubject('');
  public resetProductField$ = this.resetProductField.asObservable();
  sendResetProductField(data) {
    this.resetProductField.next(data)
  } 
  private customerBrandId: BehaviorSubject<any> = new BehaviorSubject('');
  public customerBrandId$ = this.customerBrandId.asObservable();
  sendcustomerBrandId(data) {
    this.customerBrandId.next(data)
  } 
  private customerCountryId: BehaviorSubject<any> = new BehaviorSubject('');
  public customerCountryId$ = this.customerCountryId.asObservable();
  sendcustomerCountryId(data) {
    this.customerCountryId.next(data)
  } 

  private preloadingStrategy: Subject<any> = new Subject();
  public preloadingStrategy$ = this.preloadingStrategy.asObservable();
  sendPreloadingStrategyData(data) {
      this.preloadingStrategy.next(data);
  }

  private teamAccess: Subject<any> = new Subject();
  public teamAccess$ = this.teamAccess.asObservable();
  sendTeamAccessData(data) {
      this.teamAccess.next(data);
  }

  private dashboardCurrencyData: BehaviorSubject<any> = new BehaviorSubject(null);
  public dashboardCurrencyData$ = this.dashboardCurrencyData.asObservable();
  senddashboardCurrencyData(data) {
    this.dashboardCurrencyData.next(data)
  }

  private selectedScorecardData: BehaviorSubject<any> = new BehaviorSubject(null);
  public selectedScorecardData$ = this.selectedScorecardData.asObservable();
  sendSelectedScorecardData(data) {
    this.selectedScorecardData.next(data)
  }

  private refreshOnAdminDataRoleUpdate: BehaviorSubject<any> = new BehaviorSubject(null);
  public refreshOnAdminDataRoleUpdate$ = this.refreshOnAdminDataRoleUpdate.asObservable();
  sendRefreshOnAdminDataRoleUpdate(data) {
    this.refreshOnAdminDataRoleUpdate.next(data)
  }

  private profileScrapedDate: BehaviorSubject<any> = new BehaviorSubject(null);
  public profileScrapedDate$ = this.profileScrapedDate.asObservable();
  sendProfileScrapedDate(data) {
    this.profileScrapedDate.next(data)
  }

  // Roles Count
  private rolesCount: Subject<number> = new Subject();
  rolesCount$ = this.rolesCount.asObservable();

  emitRolesCount(data: number) {
    this.rolesCount.next(data)
  }

  //Reporting Filter
  private reportingFilter: Subject<any> = new Subject();
  public reportingFilter$ = this.reportingFilter.asObservable();
  sendReportingFilter(data) {
    this.reportingFilter.next(data);
  }

  //Reporting Global Date Filter
  private reportingGlobalDateFilter: Subject<any> = new Subject();
  public reportingGlobalDateFilter$ = this.reportingGlobalDateFilter.asObservable();
  sendReportingGlobalDate(data) {
      this.reportingGlobalDateFilter.next(data);
  }

  //Reporting Global Criteria Filter
  private reportingGlobalCriteriaFilter: Subject<any> = new Subject();
  public reportingGlobalCriteriaFilter$ = this.reportingGlobalCriteriaFilter.asObservable();
  sendReportingGlobalCriteria(data) {
      this.reportingGlobalCriteriaFilter.next(data);
  }

  private reportingCriteria: string = 'sales';
  set currentReportingCriteria(criteria: string){
    this.reportingCriteria = criteria;
  }
  get currentReportingCriteria(){
    return this.reportingCriteria;
  }

  //Dashboard Marketplace Global Filter
  private dashboardMPGlobalFilter: Subject<any> = new Subject();
  public dashboardMPGlobalFilter$ = this.dashboardMPGlobalFilter.asObservable();
  sendDashboardMPGlobalFilter(data) {
        this.dashboardMPGlobalFilter.next(data);
  }

  //Dashboard Advanced Global Filter
  private dashboardAdvancedGlobalFilter: Subject<any> = new Subject();
  public dashboardAdvancedGlobalFilter$ = this.dashboardAdvancedGlobalFilter.asObservable();
  sendDashboardAdvancedGlobalFilter(data) {
        this.dashboardAdvancedGlobalFilter.next(data);
  }

  private dashboardGlobalFilterData = null;
  set currentDashboardFilterAfterTabSwitch(filters:{selectedCurrency:any,filter:any}){
    this.dashboardGlobalFilterData = filters;
  }
  get currentDashboardFilterAfterTabSwitch(){
    return this.dashboardGlobalFilterData;
  }

  private guideIndicator = false;
  set guideEnableIndicator(status: boolean){
    this.guideIndicator = status;
  }
  get guideEnableIndicator(){
    return this.guideIndicator;
  }

  private guideEnablerCheck: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public guideEnablerCheck$ = this.guideEnablerCheck.asObservable();
  setGuideEnabler(data) {
        this.guideEnablerCheck.next(data);
  }


  private insightsFirstProfieSelector: Subject<any> = new Subject();
  public insightsFirstProfieSelector$ = this.insightsFirstProfieSelector.asObservable();
  setInsightsFirstProfile(data) {
        this.insightsFirstProfieSelector.next(data);
  }

  private refreshSellout: Subject<boolean> = new Subject();
  public refreshSellout$ = this.refreshSellout.asObservable();
  refreshSelloutTab(data:boolean) {
        this.refreshSellout.next(data);
  }

  private refreshProfileGrid: Subject<boolean> = new Subject();
  public refreshProfileGrid$ = this.refreshProfileGrid.asObservable();
  sendRefreshProfileGridRequest(data: boolean) {
    this.refreshProfileGrid.next(data);
  }

  private secondaaryMenuToggle: Subject<boolean> = new Subject();
  public secondaaryMenuToggleSignal$ = this.secondaaryMenuToggle.asObservable();
  sendSecondaryMenuToggleSignal(data: boolean) {
    this.secondaaryMenuToggle.next(data);
  }

  private graphSliceClick: Subject<any> = new Subject();
  public onGraphSliceClick$ = this.graphSliceClick.asObservable();
  sendGraphSliceData(data) {
    this.graphSliceClick.next(data);
  }
  private onDirectMappingChange: BehaviorSubject<any> = new BehaviorSubject(null);
  public onDirectMappingChange$ = this.onDirectMappingChange.asObservable();
  sendDirectMappingChange(data) {
    this.selloutReportFilter = data;
    this.onDirectMappingChange.next(data);
  }
  private openOptionalFilter: Subject<any> = new Subject();
  public openOptionalFilter$ = this.openOptionalFilter.asObservable();
  sendOpenOptionalFilter(data) {
    this.openOptionalFilter.next(data);
  }

  private timeLineFilter: Subject<any> = new Subject();
  public timeLineFilter$ = this.timeLineFilter.asObservable();
  sendTimeLineFilterFilter(data) {
        this.marketShareFilter = data;
        this.timeLineFilter.next(data);
  }

  private marketShareFilterData = {};
  set marketShareFilter(data){
    this.marketShareFilterData = data;
  }
  get marketShareFilter(){
    return this.marketShareFilterData;
  }

  private selloutReportFilterContainer = {};
  set selloutReportFilter(data){
    this.selloutReportFilterContainer = data;
  }
  get selloutReportFilter(){
    return this.selloutReportFilterContainer;
  }

  setDefaultSelloutReportFilter(){
    this.selloutReportFilterContainer = {criterion:'sales', type:'overall'}
  }
  private fireMenuCount: Subject<string> = new Subject();
  public fireMenuCount$ = this.fireMenuCount.asObservable();
  sendfireMenuCount(data) {
        this.fireMenuCount.next(data);
  }

  private scorecardBreadcrumb: Subject<any> = new Subject();
  public scorecardBreadcrumb$ = this.scorecardBreadcrumb.asObservable();
  sendScorecardBreadcrumb(data) {
    this.scorecardBreadcrumb.next(data);
  }

  private discoverBreadcrumb: Subject<any> = new Subject();
  public discoverBreadcrumb$ = this.discoverBreadcrumb.asObservable();
  sendDiscoverBreadcrumb(data) {
    this.discoverBreadcrumb.next(data);
  }

  private harmonisationMappingDelete: Subject<{harmonisedId:number|string,mappings:number[]}> = new Subject();
  public harmonisationMappingDelete$ = this.harmonisationMappingDelete.asObservable();
  sendMappedHarmonisedIDs(data) {
    this.harmonisationMappingDelete.next(data);
  }

  private frequencyListAvailability: Subject<boolean> = new Subject();
  public frequencyListAvailability$ = this.frequencyListAvailability.asObservable();
  sendFrequencyListAvailabilitySignal(data: boolean) {
    this.frequencyListAvailability.next(data);
  }

  private afterLoginSuccess: Subject<boolean> = new Subject();
  public afterLoginSuccess$ = this.afterLoginSuccess.asObservable();
  sendSignalAfterSuccessLogin(data:boolean) {
      this.afterLoginSuccess.next(data);
  }

  private marketplaceConfiguration: Subject<[]> = new Subject();
  public marketplaceConfiguration$ = this.marketplaceConfiguration.asObservable();
  sendMarketplaceConfiguration(data:[]) {
      this.marketplaceConfiguration.next(data);
  }

  private isSecondaryScorecardFilterSet: Subject<any> = new Subject();
  public isSecondaryScorecardFilterSet$ = this.isSecondaryScorecardFilterSet.asObservable();
  sendisSecondaryScorecardFilterSet(data) {
    this.isSecondaryScorecardFilterSet.next(data);
  }

  private signalOnHarmonisationCrud: Subject<boolean> = new Subject();
  public signalOnHarmonisationCrud$ = this.signalOnHarmonisationCrud.asObservable();
  sendSignalOnHarmonisationCrud(data: boolean) {
    this.signalOnHarmonisationCrud.next(data);
  }
  
  private latestDataMessageSet: Subject<any> = new Subject();
  public latestDataMessageSet$ = this.latestDataMessageSet.asObservable();
  sendLatestDataMessageSet(data) {
    this.latestDataMessageSet.next(data);
  }
}
