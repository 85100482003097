import { Injectable } from '@angular/core';
import { HttpRequestService } from '@ds-shared/common-services/http-services/http-request.service';
import { PAYLOAD, TIMEVIEW } from '@ds-shared/enums/common.enum';
import { SingleDiscardProduct } from '@ds-shared/models/scorecard.model';
import { Resource, Service } from 'ngx-jsonapi';

export class ScorecardList extends Resource {
  public attributes = {
    type: '',
    id: '',
  };
}
@Injectable({
  providedIn: 'root'
})
export class ScorecardListService extends Service<ScorecardList>{
  public resource = ScorecardList;
  public type = '';
  public ttl = 1;
  changeType(type:string){
    this.type = `${type}-list`
  }
}

@Injectable({
  providedIn: 'root'
})
export class ScorecardListExtraService extends Service<ScorecardList>{
  public resource = ScorecardList;
  public type = 'scorecard';
  public ttl = 1;
}

@Injectable({
  providedIn: 'root'
})
export class HarmonisedListExtraService extends Service<ScorecardList>{
  public resource = ScorecardList;
  public type = 'harmonisation-by-type';
  public ttl = 1;
}

@Injectable({
	providedIn: "root"
})
export class ScorecardService extends Service<ScorecardList> {
	SAMPLE_FILE_DOWNLOAD_LINK = "scorecard/sample-import-file";

  constructor(public http: HttpRequestService) { 
    super();
  }
  getPricingHistory(type, id, filterDate, trend_type, view: string, trendView = TIMEVIEW.DAILY,optionalParams?) {
    const pathForPriceHistoryGraphDetails = `scorecard/store-scorecard-trend-history?filter[scorecard_type]=${type}&filter[${view}_id]=${id}${filterDate?.startDate ? `&filter[start_date]=${filterDate?.startDate}` : ''}${filterDate?.endDate ? `&filter[end_date]=${filterDate?.endDate}` : ''}&filter[trend_type]=${trend_type}&filter[trend_view]=${trendView}${optionalParams ? optionalParams : ''}`;
    return this.http.callGetApi(pathForPriceHistoryGraphDetails);
  }
  getScorecardShareData(scorecardId: number, scorecardType: string, shareType: string, view:string='scorecard', optionalParams?,spliceFilter?,filterDate?) {
    const filter = `filter[${view}_id]=${scorecardId}&filter[scorecard_type]=${scorecardType}&filter[share]=${shareType}${optionalParams ? optionalParams : ''}${spliceFilter ? spliceFilter : ''}&filter[base_type]=${shareType === 'category' ? 'name' : 'id'}${filterDate?.startDate ? `&filter[start_date]=${filterDate?.startDate}` : ''}${filterDate?.endDate ? `&filter[end_date]=${filterDate?.endDate}` : ''}` 
    return this.http.callGetApi(`scorecard/share-of-search?${filter}`);
  }

	getMarketplaceList(
		pageSize: Number,
		pageNumber: Number,
		searchText: string,
		url: string
	) {
		return this.http.callGetApi(
			`common/marketplace-list?page[size]=${pageSize}&page[number]=${pageNumber}${
				searchText && `&filter[name]=${searchText}`
			}${url ? `&filter[marketplace_url]=${url}` : ""}`
		);
	}
	public deleteProfile(profiletype: any, profileId: number) {
		return this.http.callDeleteApi(`scorecard/customer-profile/${profileId}`);
	}
	public updateProfile(data: any, profileType: string, profileId: number) {
		data["id"] = profileId;
		return this.http.callPutApi(`scorecard/update-profile`, data);
	}

	public getMappedData(profileType, profileId) {
		return this.http.callGetApi(
			`scorecard/mapped-profiles?is_customer=${
				profileType == "your" ? "customer" : profileType
			}&id=${profileId}`
		);
	}

	public exportProfiles(exportType: string) {
		return this.http.callAWSExport(exportType);
	}

	public importProfiles(payload: {}) {
		return this.http.callPostApi(`scorecard/bulk-import`, payload);
	}

	public downloadSampleFile() {
		return this.http.getFileAsBlob(this.SAMPLE_FILE_DOWNLOAD_LINK);
	}
	public changeProfileStatus(data: any) {
		return this.http.callPutApi(`scorecard/start-stop-scraping`, data);
	}
	public getProfileLabels(profileType: boolean) {
		return this.http.callGetApi(
			`scorecard/list-labels?is_brand_label=${profileType}`
		);
	}
	public bulkDelete(path: string, payload: any) {
		return this.http.callPostApi(`${path}`, payload);
	}
	public sortByList(profile_type: string, key: string, value: any) {
		return this.http.callGetApi(
			`scorecard/sort-param-mp?profile_type=${profile_type}&filter[${key}]=${value}`
		);
	}
	public pageLimitData(
		profile_type: string,
		key: string,
		value: any,
		is_brand_store: boolean
	) {
		return this.http.callGetApi(
			`scorecard/page-product-count?profile_type=${profile_type}&filter[${key}]=${value}&is_brand_store=${is_brand_store}`
		);
	}
	public createOrUpdateProfile(method: string, data: any, profileId?: number) {
		if (method === "post") {
			return this.http.callPostApi("scorecard/update-profile", data);
		} else {
			data["id"] = profileId;
			return this.http.callPutApi(`scorecard/update-profile`, data);
		}
	}
	public getProfileInfo(type: string, profileId: number) {
		return this.http.callGetApi(
			`scorecard/profile-list?filter[profile_type]=${type}&profile_id=${profileId}`
		);
	}

	public getHarmonisationEntity(type, currency_id) {
		return this.http.callGetApi(
			`common/harmonised-entity-list?filter[harmonisation_type]=${type}&filter[currency_id]=${currency_id}`
		);
	}
	public addHarmonisation(type: string, body: {}) {
		return this.http.callPostApi(
			`harmonisation/harmonisation-by-type?filter[harmonisation_type]=${type}`,
			body
		);
	}
	public discardFromAllTrackers(payload: SingleDiscardProduct[]) {
		return this.http.callPostApi(
			`scorecard/all-profiles-toggle-discard-products`,
			payload
		);
	}
	public getCategoryList() {
		return this.http.callGetApi(`scorecard/category-product-matching-list`);
	}
	public getSampleURL(profileType: string) {
		return this.http.callGetApi(
			`scorecard/marketplace-tracker-examples?profile_type=${profileType}`
		);
	}
	public getHarmonisedBannerData(type: string, id: string | number) {
		return this.http.callGetApi(
			`scorecard/harmonised-list?filter[harmonisation_type]=${type}&filter[harmonised_id]=${id}`
		);
	}
	public checkMarketplaceURL(url: string, profile_type: string) {
		return this.http.callGetApi(
			`scorecard/check-marketplace-url?filter[marketplace_url]=${url}&filter[profile_type]=${profile_type}`
		);
  }
	public getFrequencyList(profile_type: string, key: string, value: any) {
		return this.http.callGetApi(`common/list-frequencies?filter[${key}]=${value}`);
  }
}
