import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '@ds-shared/common-services/http-services/global.service';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';
import { CustomValidationsService } from '@ds-shared/common-services/utility-services/custom-validations.service';
import { NotificationService } from '@ds-shared/common-services/utility-services/notification.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public resetPasswordForm: UntypedFormGroup;
  public displayPassword: any = {};
  public formSubmitted: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private notificationService: NotificationService,
    private customValidations: CustomValidationsService,
    public commonFunctions: CommonFunctionsService,
    public router: Router,
  ) { }
  private destroy$: Subject<boolean> = new Subject();
  ngOnInit(): void {
    const phash = this.route.snapshot.queryParamMap.get('phash');
    let email: string;
     this.route.queryParams.pipe().subscribe((params)=> {
      if(params['e'])  email = window.atob(params['e']);
    })
    if(!phash) {
      this.router.navigate(['']);
    }
    this.resetPasswordForm = new UntypedFormGroup({
        email: new UntypedFormControl(null),
        new_password: new UntypedFormControl(null, [Validators.required, this.validateNewPassword.bind(this)]),
        confirm_password: new UntypedFormControl(null, [Validators.required]),
    });
      this.resetPasswordForm.controls["email"].disable();
      this.resetPasswordForm.controls["email"].setValue(email);
      const myInput = document.getElementById('confirm-password');
      myInput.onpaste = e => e.preventDefault();
      this.getPasswordStatus();
      this.getResetPasswordDetails(phash, email);
  }

  validateNewPassword(control: UntypedFormControl): ValidationErrors | null {
    return this.customValidations.validatePasswordPattern(control);
  }

  isValidBase64(str) {
    try {
        window.atob(str);
        return true;
    } catch (e) {
        return false;
    }
}

  getPasswordStatus() {
    this.displayPassword = {
       'newState': true, 'confirmState': true
    };
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.resetPasswordForm.status == "VALID") {
      if (this.resetPasswordForm.get("new_password").value !== this.resetPasswordForm.get("confirm_password").value) {
        this.resetPasswordForm.controls['confirm_password'].setValue(null);
        this.resetPasswordForm.controls['confirm_password'].setErrors({ 'confirmError': 'Passwords didn’t match. Try again.' });
      }
      else {
        let data = {
          email: this.resetPasswordForm.get("email").value,
          new_password: this.resetPasswordForm.get("new_password").value
        };
        this.globalService.updateNewPasswordApi(data)
          .subscribe((res: any) => {
            if (res.context_code === 1000) {
              this.notificationService.setMessage(1000, res.summary);
              this.router.navigate(['']);
            }
          }, (err: any) => {
            this.formSubmitted = false;
            const errMsg  = err.error.summary ? err.error.summary : "Something went wrong, please try again."
            this.notificationService.setMessage(1100, errMsg);
          });
      }
    }
  }

  getResetPasswordDetails(phash: string, email: string) {
    this.globalService.getResetPasswordUserId(phash, email).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
          if(email !== res.data?.attributes.email) {
            this.router.navigate(['']);
            this.notificationService.setMessage(1100, res.summary ? res.summary : this.commonFunctions.getSpecificTranslation("Something went wrong, please try again."));
          }
          else {
            this.resetPasswordForm.controls["email"].setValue(res.data.attributes.email);
          }
        },
    (err) => {
      this.globalService.logout();
      this.router.navigate(['']);
      const errMsg  = err.error.summary ? err.error.summary : "Something went wrong, please try again."
      this.notificationService.setMessage(1100, errMsg);
    })
  }

  showPasswordFunc(type: any) {
    this.displayPassword[type] = !this.displayPassword[type];
  }

  public ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

}
