import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';
import { HealthGridKPI } from '@ds-shared/models/dashboard.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonGridPopUpComponent } from '@ds-shared/common-components/common-grid-pop-up/common-grid-pop-up.component';
import { DataTransferService } from '@ds-shared/common-services/utility-services/data-transfer.service';
import { DOCUMENT } from '@angular/common';
import { Subject, finalize, takeUntil } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@ds-shared/common-services/utility-services/notification.service';
import { LocalStorageService } from '@ds-shared/common-services/storage-services/local-storage.service';
import { AI_SERVICES } from '@ds-shared/enums/common.enum';
import { RolePermissions } from '@ds-shared/models/access-matrix.model';
import { AccessMatrixService } from '@ds-shared/common-services/utility-services/access-matrix.service';
import { Modules } from '@ds-shared/enums/modules.enum';
import { GlobalService } from '@ds-shared/common-services/http-services/global.service';
import { MixpanelService } from '@ds-shared/common-services/utility-services/mixpanel.service';
import { HarmonisationService } from '@ds-private-layouts/configuration/harmonisation/harmonisation.service';
import { HarmonisationUnmapActionComponent } from '@ds-shared/common-components/actions/harmonisation-unmap-action/harmonisation-unmap-action.component';

@Component({
  selector: 'app-common-mapping',
  templateUrl: './common-mapping.component.html',
  styleUrls: ['./common-mapping.component.scss']
})
export class CommonMappingComponent implements OnInit, OnDestroy {
  @Input() public col:any;
  @Input() public item: any;
  @Input() public kpi: HealthGridKPI;
  @Input() public isNAForRestriction: boolean = false;

  @Input() public moduleType;
  @Output() navigationTrigger: EventEmitter<{}> = new EventEmitter();
  @Output() trendActionTrigger: EventEmitter<{}> = new EventEmitter();
  @ViewChild(CommonGridPopUpComponent)
  commonGridPopUpComponent: CommonGridPopUpComponent;
  public randomDropdownID: string = '';
	public permissions: RolePermissions = new RolePermissions();
  public differentBadges:any  = {
    OS: { name: "Official", class: 'badge-dark-orange' },
    GS: { name: "Greyseller", class: 'badge-grey' },
    RS: { name: "OARP (Reseller)", class: 'badge-orange' },
    CP: { name: "Competitor", class: 'badge-blue' },
    OT: { name: "Others", class: 'badge-others' },
    true: { name: "Active", class: 'badge-active' },
    false: { name: "Paused", class: 'badge-paused' },
    Mapped: { name: "Mapped", class: 'badge-mapped' },
    Unmapped: { name: "Unmapped", class: 'badge-unmapped' }
  }
  public booleanKeyObj = {
    'team-member-status': {
      true: { name: "Activated", class: 'badge-active' },
      false: { name: "Deactivated", class: 'badge-paused' }
    }
  }
  public globalCurrencyDetails;
  private destroy$: Subject<boolean> = new Subject();
  public AI_SERVICES = AI_SERVICES;
  public variantData = {
    subscription: null,
    isLoading: false,
    list: [],
    defaultSelected: [],
    selected: [],
    checked: [],
    unchecked: [],
    updated: false
  }
  public variantSelected = {}
  public mappedSelected = [];
  public mappedEntitySelected = null;
  constructor(public commonFunctions: CommonFunctionsService, public route: ActivatedRoute, public dataTransferService: DataTransferService, @Inject(DOCUMENT) private document: Document, private router: Router, private ngbModal: NgbModal, private notificationService: NotificationService, private localStorageService: LocalStorageService, private accessMatrixService: AccessMatrixService, private globalService: GlobalService, private mixpanelService: MixpanelService,
    public harmonisationService: HarmonisationService, private modalService: NgbModal) {
    this.permissions = this.accessMatrixService.getPermissionByRoute();
    this.randomDropdownID = this.commonFunctions.randomId(10);
    this.globalCurrencyDetails = this.commonFunctions.currencySelected.attributes
    this.getCurrencyForSpecificComponents();
   }

  public ngOnInit(): void {
  }

  /** This method handles product face hiding problem for product grids or 
  wherever product details popup involves on hovering product image */
  public onDropdownOpenClose(drop,eventDetails){
    if(eventDetails){
      setTimeout(() => {
        const dropdownMenu =  this.document.getElementById(this.randomDropdownID);
        const rect = drop.getBoundingClientRect();
        if(dropdownMenu){
          dropdownMenu.style.position = 'fixed'
          dropdownMenu.style.top = `${rect.top}px`
          dropdownMenu.style.left = `${rect.left + window.scrollX + 50}px`
          dropdownMenu.style.zIndex = '9999'
          // checkForClipping will return bottom overlap value as integer
          const checkForClipping = this.isClippingOffViewport(dropdownMenu);
          if(checkForClipping){
            dropdownMenu.style.top = `${rect.top + this.minusPercentage(checkForClipping,210)}px`
          }
          dropdownMenu.classList.remove("invisible");
          this.document.querySelectorAll('app-root')[0].append(dropdownMenu)
        }
     
      }, 0);
    }else{
      document.getElementById(this.randomDropdownID).remove();
    }
  }

  private getCurrencyForSpecificComponents(){
    this.dataTransferService.dashboardCurrencyData$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if(this.router.url.includes('profile-information')) this.globalCurrencyDetails = res;
    });
  }

  private minusPercentage(val,percentage){
    return val * (100 - percentage) /100
  }

  private isClippingOffViewport(element) {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || this.document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || this.document.documentElement.clientWidth;
  
    const isClippingVertically = rect.bottom > windowHeight || rect.top < 0;
    const isClippingHorizontally = rect.right > windowWidth || rect.left < 0;
  
    if(isClippingVertically){
      const topOverlap = Math.max(0, rect.top);
      const bottomOverlap = Math.max(0, rect.bottom - window.innerHeight);
     return bottomOverlap;
    }
    // return isClippingVertically || isClippingHorizontally;
    return false;
  }

  public getValueToDisplay(item: any, cols: any, kpi:any) {
    const NA_LIST_OF_KPI = ["Overpriced", "Underpriced", 'Increased Traffic'];
    const NA_LIST_OF_BADGE = ["OS", "RS"]
    if(this.isNAForRestriction){
      kpi['isNA'] = true
      NA_LIST_OF_KPI.push(kpi.name)
    }
    if (NA_LIST_OF_KPI.indexOf(kpi.name) > -1 && NA_LIST_OF_BADGE.indexOf(cols?.map?.segment) === -1) {
      kpi['isNA'] = true
      return  this.commonFunctions.getMappedObject(item,kpi?.map?.value) ? this.commonFunctions.getMappedObject(item,kpi?.map?.value) + "%" : 'NA';
    }
    else return  this.commonFunctions.getMappedObject(item,kpi?.map?.value) ? this.commonFunctions.getMappedObject(item,kpi?.map?.value) + "%" : this.commonFunctions.getMappedObject(item,kpi?.map?.value)===0?'0%':'--';
  }

  public onNavigationClick(item, map) {
    this.navigationTrigger.emit({ item, map })
  }

  openModalForUntrackedProfile(type) {
    this.notificationService.setMessage(
      1200,
      `${this.commonFunctions.capitalizeFirstLetter(type)} information will be updated after next data run`
    );
  }

  public onCountClick(clickable: boolean){
    if(!clickable) return;
    
    this.navigationTrigger.emit(this.item)
  }
  public navigateToDiscover(type: 'store' | 'product',id: number, profileId?: number) {
    this.router.navigate([]).then(result => {  window.open(`/discover/${type}-compare/` + id + (profileId ? `?profileId=${profileId}` : ''), '_blank'); });

  }

  getRawHeaderName(mapped: boolean, count: number=0) {
    let moduleName: string;
    const name = this.moduleType.name.split('-')[0]
    if (mapped) {
      moduleName = count <= 1 ? name : (name === 'category' ? 'categories' : `${name}s`) ;
    }
    else {
      moduleName = name === 'category' ? 'categories' : `${this.moduleType.name}s`;
    }
    return moduleName;
  }

  getArrayEle(sentimentObj) {
    return [
      { name: "positive",value: sentimentObj['positive_reviews_perc'], color: "#009d2e" },
      { name: "neutral", value: sentimentObj['neutral_reviews_perc'],color: "#ff9800" },
      { name: "negative", value:sentimentObj['negative_reviews_perc'], color: "#ff1948" },
    ]
  }

  getListOfProducts(listOfBuzzword: string[], id: number, name: string) {
    this.navigationTrigger.emit({listOfBuzzword, category_id:id, name: name})
  }

  public downloadFile(){
    this.notificationService.setMessage(
      1200,
      this.commonFunctions.getSpecificTranslation("Your download will begin shortly.")
    );
    const endpoint = this.item.attributes?.path;
    const filename = this.item.attributes?.file_name;
    this.globalService.getExportApi(endpoint).subscribe((res: Blob) => {
      this.notificationService.clearMessage();
      this.mixpanelService.track( 'File Downloaded - '+filename, {});
      this.notificationService.setMessage(
        1000,
        this.commonFunctions.getSpecificTranslation("File downloaded successfully.")
      );
      const url = window.URL.createObjectURL(res);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = 'ADS_'+ filename +'.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
    (err: any) => {
      this.notificationService.setMessage(
        1100,
        this.commonFunctions.getSpecificTranslation("Something went wrong, please try again.")
      );
    }
  );

  }
  public getVariantList(variantMapping, parent_product_id: number) {
    this.ngbModal.open(variantMapping, {
      centered: true,
      windowClass: "customModal variantModal",
    });
    this.variantData.subscription?.unsubscribe();
    this.variantData.isLoading = true;
    this.variantData.list = [];
    this.variantData.defaultSelected = [];
    this.variantData.selected = [];
    this.variantData.checked = [];
    this.variantData.unchecked = [];
    this.variantData.updated = false;
    const harmonised_id = parseInt(this.commonFunctions.getDecodedData(this.route["snapshot"]["params"]["productId"]));
    this.variantData.subscription = this.harmonisationService.getVariantList(parent_product_id, true, harmonised_id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.variantData.isLoading = false;
        this.variantData.list = res.data;
        this.variantData.defaultSelected = res.data.filter(item => item.checked).map(i => i.id);
        this.variantData.selected = [...this.variantData.defaultSelected];
      })
  }

  public onVariantChange(eventDetails, variant) {
    if (eventDetails.target.checked) {
      if (variant) this.variantData.selected.push(variant.id);
      else this.variantData.selected = this.variantData.list.map(item=>item.id)
    } else {
      if (variant) {
        const index = this.variantData.selected.indexOf(variant.id);
        if (index !== -1) {
          this.variantData.selected.splice(index, 1);
        }
      }
      else this.variantData.selected = JSON.parse(JSON.stringify([]));
    }
    this.variantData.checked = this.variantData.selected.filter(item => !this.variantData.defaultSelected.includes(item))
    this.variantData.unchecked = this.variantData.defaultSelected.filter(item => !this.variantData.selected.includes(item))
    this.variantData.updated = this.variantData.checked.length!==0 || this.variantData.unchecked.length!==0;
  }

  public updateVariantMapping() {
    const harmonised_id = parseInt(this.commonFunctions.getDecodedData(this.route["snapshot"]["params"]["productId"]));
    const { checked, unchecked } = this.variantData;

    const createPayload = (ids) => ({
      "harmonisation_type": "product",
      "raw_ids": ids,
      "harmonised_id": harmonised_id
    });

    const map_payload = checked.length ? createPayload(checked) : null;
    const remove_payload = unchecked.length ? createPayload(unchecked) : null;
    this.harmonisationService.udpateVariantMapping(map_payload, remove_payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.notificationService.setMessage(
          res[0]["context_code"],
          res[0]["summary"]
        );
        this.ngbModal.dismissAll();
      }, (err: any) => {
        this.notificationService.setMessage(
          err["error"]["context_code"],
          err["error"]["summary"]
        );
      })
  }

  public onHarmonisedMappedCheckUncheck(eventDetails, rawData, list) {
    if (eventDetails.target.checked) {
      if (rawData) this.mappedSelected.push(rawData.id);
      else { 
        this.mappedSelected = list.map(item => item.id)
      }
    } else {
      if (rawData) {
        const index = this.mappedSelected.indexOf(rawData.id);
        if (index !== -1) {
          this.mappedSelected.splice(index, 1);
        }
      }
      else { 
        this.mappedSelected = JSON.parse(JSON.stringify([]));
      }
    }
  }

  public onTrendAction(){
    this.trendActionTrigger.emit(this.item)
  }

  openHarmonisedUnmapModal(harmonised_id, mappings, item) {
    const modalRef = this.modalService.open(HarmonisationUnmapActionComponent, {
      centered: true,
      windowClass: "customModal harmonisedConfirmModal",
      keyboard: false,
      backdrop: "static"
    });
    const type = this.moduleType.name.split('-')[0];
    const mod_type = `${type === "category" && mappings.length > 1 ? 'Categories' : type + (mappings.length > 1 ? "s" : "")}`;
    const tracker_plural = item.attributes.linked_tracker.length > 1 ? "trackers" : "tracker";
    const tracker_text = item.attributes.linked_tracker.length ? `<b>${item.attributes.name}</b> is linked to <b>${item.attributes.linked_tracker.map(data => data.name).join(", ")}</b> ${tracker_plural}.` : '';
    modalRef.componentInstance.popupInfo = {
      mainTitle: `Unmap ${this.commonFunctions.capitalizeFirstLetter(mod_type)}`,
      submitTitle: `Yes, Unmap ${this.commonFunctions.capitalizeFirstLetter(mod_type)}`,
      description: `${tracker_text}\nAre you sure you want to upmap <b>${mappings.length}</b> selected ${mod_type} from <b>${item.attributes.name}</b> ?`
    }; 
    modalRef.result.then((result) => {
      if (!result) return;
      this.dataTransferService.sendMappedHarmonisedIDs({ harmonisedId: harmonised_id, mappings: mappings })
    }, (reason) => {
    });
  }

  public ngOnDestroy(){
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
