import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { HarmonisationService } from "@ds-private-layouts/configuration/harmonisation/harmonisation.service";
import { AccessMatrixService } from "@ds-shared/common-services/utility-services/access-matrix.service";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";
import { CustomValidationsService } from "@ds-shared/common-services/utility-services/custom-validations.service";
import { DataTransferService } from "@ds-shared/common-services/utility-services/data-transfer.service";
import { NotificationService } from "@ds-shared/common-services/utility-services/notification.service";
import { Modules } from "@ds-shared/enums/modules.enum";
import { RolePermissions } from "@ds-shared/models/access-matrix.model";
import { ModuleType } from "@ds-shared/models/common.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, Subscription, takeUntil } from "rxjs";

@Component({
	selector: "app-harmonization-actions",
	templateUrl: "./harmonization-actions.component.html",
	styleUrls: ["./harmonization-actions.component.scss"]
})
export class HarmonizationActionsComponent implements OnInit, OnDestroy {
	@Input() public moduleType: ModuleType;
	@Input() public actionData: any;
	@Input() public actionType: string;
	@Input() public col;
	@Input() public meta;
	@Input() public pageSize;
	
	@Output() refreshGrid = new EventEmitter();
	private destroy$: Subject<boolean> = new Subject();
	public clearLastCall: Subscription;
	public permissions: RolePermissions = new RolePermissions();
	public harmonisedName: UntypedFormControl = new UntypedFormControl("", [
		Validators.required
	]);
	public trackers: string = "";

	constructor(
		public commonFunctions: CommonFunctionsService,
		private modalService: NgbModal,
		private route: Router,
		private accessMatrixService: AccessMatrixService,
		private harmonisationService: HarmonisationService,
		private notificationMessageService: NotificationService,
		private customValidationService: CustomValidationsService,
		private dataTransferService: DataTransferService
	) {
		this.permissions = this.accessMatrixService.getPermissionByRoute();
	}

	ngOnInit(): void {
	}

	public openModal(content, isEditPopup) {
		this.modalService.open(content, {
			centered: true,
			windowClass: "customModal harmonisedModal",
			keyboard: false,
			backdrop: "static"
		});
		this.harmonisedName.patchValue(this.actionData.attributes.name);
		this.trackers = this.actionData.attributes.linked_tracker.map(data => data.name).join(", ");
	}

	public updateHarmonisationData() {
		this.harmonisationService
			.editHarmonisation(this.moduleType.name, this.actionData.id, {
				name: this.harmonisedName.value
			})
      .pipe(takeUntil(this.destroy$))
			.subscribe((res) => {
        if (res) {
          this.refreshGrid.emit(true);
          this.notificationMessageService.setMessage(
            res["context_code"],
            res["summary"]
          );
        }
      },(err)=>{
		this.notificationMessageService.setMessage(
			err.error["context_code"],
			err.error["summary"]
		  );
	  });
	}

	public delHarmonisation() {
		this.harmonisationService
			.deleteHarmonisation(this.moduleType.name, this.actionData.id)
      .pipe(takeUntil(this.destroy$))
			.subscribe(
				(res) => {
					if (res) {
						const pageNo = this.commonFunctions.getPageNo(this.meta,this.pageSize);
						this.refreshGrid.emit({isRefresh: true, isDelete: true , pageNo: pageNo});
						this.dataTransferService.sendSignalOnHarmonisationCrud(true)
						this.notificationMessageService.setMessage(
							res["context_code"],
							res["summary"]
						);
					}
				},
				(err: any) => {
					this.notificationMessageService.setMessage(
						err.error.context_code,
						err.error.summary
					);
				}
			);
	}

  ngOnDestroy(){
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
