import { Component, ElementRef, Input, OnInit, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';
import { ColumnModel, CommonPopUpModel, CommonSearchModel, MarketplaceConfiguration, ModuleType } from '@ds-shared/models/common.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentCollection } from 'ngx-jsonapi';
import { Subject, takeUntil, Subscription} from 'rxjs';
import { PopUpGrid, PopUpListService, PopUpListExtraService, GlobalService } from '@ds-shared/common-services/http-services/global.service';
import { COL, PAYLOAD } from '@ds-shared/enums/common.enum';
import { MixpanelService } from '@ds-common-services/utility-services/mixpanel.service';
import { DataTransferService } from '@ds-shared/common-services/utility-services/data-transfer.service';
import { Router,ActivatedRoute} from  '@angular/router';
import { LocalStorageService } from '@ds-common-services/storage-services/local-storage.service';

@Component({
  selector: 'app-common-grid-pop-up',
  templateUrl: './common-grid-pop-up.component.html',
  styleUrls: ['./common-grid-pop-up.component.scss']
})
export class CommonGridPopUpComponent implements OnInit {
  @ViewChild('popUp') popUp: ElementRef;
  @Input() public customClass: string ='';
  @Output() tabChangeEvent = new EventEmitter<{}>();
  @Output() keywordClickEvent = new EventEmitter<{}>();
  public productList: DocumentCollection<PopUpGrid>;
  public cols: ColumnModel[];
  public NoData: boolean = false;
  public sort: string[] = [];
  public loadingData: boolean = false;
  public page: number = 1;
  public pageSize: number;
  public itemPerPage: number = 5;
  public popupExportPath;
  public popupExportParams;
  public popupExportedFileName;
  public isPopDownloadActive: boolean;
  private exportExclusiveForModule=[]

  public moduleType: ModuleType = {
    name: "pop-up-list",
    style: {
      tableStyle: {
        height: '21.5rem'
      },
      tableWrapper: {
        'border': 'none',
        'border-radius': '0rem'
      },
      noDataStyle: {
        height: '18.375rem',
      },
      paginationStyle: {
        'border': 'none',
        'padding': '1rem 0rem 0rem 0rem'
      }
    },
    pagination: {
      show: true
    }
  };
  private $destroy: Subject<boolean> = new Subject();
  public searchedName: UntypedFormControl = new UntypedFormControl('');
  public searchObj: CommonSearchModel = {
    text: '',
    placeholder: 'Search Product',
    style: { width: '19rem' }
  }
  public popUpInfo: CommonPopUpModel;
	public clearLastCall: Subscription;
  public marketplaceConfiguration:MarketplaceConfiguration = new MarketplaceConfiguration();
  @Input() showFilter:boolean = false;
  @Input() filterData;
  public selectedBuzzword: string;
  public activeBuzzword: number = 0;
  public moduleName;
  constructor(private modalService: NgbModal, public commonFunctions: CommonFunctionsService,
    private popUpListService: PopUpListService,
    public globalService: GlobalService,private mixpanelService: MixpanelService,
    public popUpListExtraService: PopUpListExtraService, private dataTransferService: DataTransferService,
    private router: Router, private route: ActivatedRoute, private localStorageService: LocalStorageService) {
      this.dataTransferService.marketplaceConfiguration$.pipe(takeUntil(this.$destroy)).subscribe((res:[])=>{
        this.marketplaceConfiguration = new MarketplaceConfiguration(res);
      })
    }

  ngOnInit(): void {
    this.moduleName = this.commonFunctions.getModuleName();
  }
 
  private setExtraServices(): void {
    this.globalService.register();
    this.popUpListService.setType(this.popUpInfo.gridInfo.path);
    this.popUpListService.register();
    this.popUpListExtraService.setType(this.popUpInfo.gridInfo.beforePath);
    this.popUpListExtraService.register();
  }

  private getPopUpDateTime(params) {
    if(params.date) return this.commonFunctions.formatSimpleDate(params.date, "YYYYMMdd");
    else if (params.start_date && params.end_date) return this.commonFunctions.getExportDateFormat({startDate: params.start_date, endDate:params.end_date});
    else return params.timeline
  }

  openModal(data) {
    this.activeBuzzword = 0;
    this.isPopDownloadActive = data?.isDownloadAvailable;
    this.popupExportPath = `${data?.gridInfo?.beforePath}/export-${data?.gridInfo?.path}`
    if(data?.gridInfo?.customExportDetails && data.gridInfo.customExportDetails.useCustomExport) {
      this.popupExportedFileName = data.gridInfo.customExportDetails.exportFileName;
    }
    else {
      this.popupExportedFileName = data?.gridInfo?.exportedFileName ? data?.gridInfo?.exportedFileName : this.commonFunctions.getDynamicExportFileName(this.getPopUpDateTime(data?.gridInfo?.params), `${data.graphName ? data.graphName : data.modelName}_${data?.infoBanner?.name ? (data?.infoBanner?.name[0]?.toUpperCase() + data?.infoBanner?.name.slice(1).toLowerCase()+'_') : ''}${data?.gridInfo?.params?.date ? " " :  (data.infoBanner?.subName ? data?.infoBanner?.subName?.text : "")}`).replace(/_+$/,'');
    }
    this.popupExportParams = data?.gridInfo?.params;
    this.popUpInfo = data;
    this.moduleType["defaultSort"] = data?.gridInfo?.defaultSort;
    this.selectedBuzzword = this.popUpInfo?.moduleRawData?.listOfBuzzword[0];
    if (this.selectedBuzzword) {
      this.popUpInfo.infoBanner.name = `Products with '${this.selectedBuzzword}' Buzzword`;
    }
    this.mixpanelService.track(this.popUpInfo.modelName + ' popup visited.',{});
    this.modalService.open(this.popUp, {
      centered: true,
      windowClass: "popUpModal",
      keyboard: false,
      backdrop: "static"
    });
    this.resetData();
    this.setExtraServices();
    this.cols = this.popUpInfo.gridInfo.cols;
    if(!this.marketplaceConfiguration.CONFIGS.quantities_available){
      const indexToRemove = this.cols.findIndex(item => item.name === COL.QUANTITY);
      if (indexToRemove !== -1) {
        this.cols.splice(indexToRemove, 1);
      }
    }
    this.initData();
  }


  public initData(keepCurrentPage?:boolean): void {
    if(keepCurrentPage) this.getProducts(this.page)
    else
    this.getProducts(1);
  }

  public onPageChange(eve: number): void {
    this.page = eve;
    this.getProducts(this.page);
  }
  public onPageSizeChange(eve: number): void {
    this.pageSize = eve;
    this.itemPerPage = eve;
    this.getProducts(1);
  }

  private getProducts(page: number): void {
    this.clearLastCall?.unsubscribe();
    this.productList = new DocumentCollection();
    const param: any = this.popUpInfo.gridInfo.params;
    param[`${PAYLOAD.SEARCH}`] = this.commonFunctions.encodeURi(this.searchObj.text?.trim());
    Object.keys(param).forEach(item => {
      if ((Array.isArray(param[item]) && !param[item]?.length) || (!Array.isArray(param[item]) && !param[item])) delete param[item];
    })
    if (!this.sort?.length && this.moduleType.defaultSort?.active) {
      this.sort = [`${this.moduleType.defaultSort.order === 'up' ? '-' : ''}${this.moduleType.defaultSort.key.split('.')[this.moduleType.defaultSort.key.split('.')?.length - 1]}`];
    }
    this.popupExportParams = {...this.popUpInfo.gridInfo.params, sort: this.sort};
    Object.keys(this.popupExportParams).forEach(item => {
      if ((Array.isArray(this.popupExportParams[item]) && !this.popupExportParams[item]?.length) || (!Array.isArray(this.popupExportParams[item]) && !this.popupExportParams[item])) delete this.popupExportParams[item];
    })
		this.clearLastCall = this.popUpListService
      .all({
        page: { number: page || 1, size: this.pageSize || 5 },
        remotefilter: param,
        sort: this.sort,
        beforepath: this.popUpInfo.gridInfo.beforePath,
      }).pipe(takeUntil(this.$destroy))
      .subscribe(
        (productList) => {
          this.loadingData = true;
          if (
            productList.source == "server" &&
            productList.is_loading == false
          ) {
            if (productList.data.length == 0) {
              this.NoData = true;
              this.loadingData = true;
            } else {
              this.loadingData = false;
              this.NoData = false;
              this.productList = productList;
            }
          } else {
            this.loadingData = true;
            this.NoData = false;
          }
        },
        (error): void => {
          this.loadingData = true;
          this.NoData = true;
        }
      );

  }

  public sortData(data: string[]): void {
    this.sort = data;
    if (this.moduleType.defaultSort) {
      this.moduleType.defaultSort.active = false;
    }
    this.initData(true);
  }

  public onSearch(text: string): void {
    this.searchObj = { ...this.searchObj, text: text };
    this.initData();
  }
  public resetData(): void{
    this.searchObj = { ...this.searchObj, text: '' };
    this.sort = [];
    this.page = 1;
    this.itemPerPage = 5;
    this.pageSize = 5;
  }

  public onFilterBy(){
    if(this.filterData.name === 'marketplace')
    this.localStorageService.set('filterDataMP',this.filterData)
    else
    this.localStorageService.set('filterData',this.filterData)
    this.router.navigate([this.router.url]).then(result => {  window.open(this.router.url, '_blank'); });
  }
  
  public onTabChange(eventDetails):void{
    this.resetData();
    const fileNameArr =   this.popupExportedFileName.split("_");
   fileNameArr[0] = eventDetails.display;
    this.popupExportedFileName = fileNameArr.join("_");
    this.cols = this.popUpInfo.gridInfo.cols;
    this.popUpInfo.infoBanner.name = this.popUpInfo.gridInfo.params?.share_type === 'segment' ? (eventDetails.code === 'product'? 'Product' : '') : this.popUpInfo.infoBanner.name;
    this.tabChangeEvent.emit(eventDetails)
  }
  
  public onKeywordClick(keyword: string, i:number) {
    this.activeBuzzword = i;
    this.selectedBuzzword = keyword;
    this.popUpInfo.infoBanner.name = `Products with '${this.selectedBuzzword}' Buzzword`;
    this.keywordClickEvent.emit(keyword);
  }

  public trackKeyword(keyword: string) {
    const profileObj = {
      url: null,
      name: keyword
    }
    this.commonFunctions.redirectToProfile('keyword', this.commonFunctions.getEncodedData(JSON.stringify(profileObj)), this.route);
  }

  public ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
