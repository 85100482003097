import { Component, OnInit, HostListener, ElementRef, Input, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CommonFunctionsService } from '@ds-common-services/utility-services/common-functions.service';

@Component({
  selector: 'showMultiTooltipOnTextOverflow,[showMultiTooltipOnTextOverflow]',
  templateUrl: './multiline-tooltip.component.html',
  styleUrls: ['./multiline-tooltip.component.scss']
})
export class MultilineTooltipComponent implements OnInit {
    @Input() data: string | number;
    @Input() ttPosition: string;
    @Input() changeHeight: string;
    public isVisible: boolean=false;
    public unqiueId:string;
    constructor(private commonFunctions: CommonFunctionsService,public _el: ElementRef, @Inject(DOCUMENT) private document: Document) {
      this.unqiueId = this.commonFunctions.randomId(10);
    }
  
    ngOnInit() { }
  
    @HostListener('mouseenter') onEnter() {
      this.isVisible = (this.document.getElementById(this.unqiueId).scrollHeight > this.document.getElementById(this.unqiueId).clientHeight) ? true : false;
    }
    @HostListener('mouseleave') onLeave() {
      this.isVisible = false;
    }
}
