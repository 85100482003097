<div ngbDropdown class="d-inline-block position-static" display="dynamic">
    <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"><span><i class="icon icon-Options"></i></span></span>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="grid-action-dropdown">
       <button [disabled]="!permissions.write_access" class="dropdown-item" (click)="openModal(editHarmonisation,true)"><i class="icon  icon-Edit-alt"></i><span translate>Update Harmonisation</span></button>
       <button [disabled]="!permissions.write_access" class="dropdown-item" (click)="openModal(deleteHarmonisation,false)"><i class="icon  icon-Trash"></i><span translate>Delete Harmonisation</span></button>
    </div>
 </div>
 <ng-template #deleteHarmonisation let-modal>
    <div class="modal-header">
       <h4 class="modal-title" translate>Delete Harmonisation</h4>
    </div>
    <div class="modal-body text-center d-flex justify-content-center align-items-center flex-column">
       <i class="icon  icon-Trash mb-2" ngbAutofocus></i>
       <p translate>{{actionData.attributes.mapped_count}} raw {{moduleType.name | plural:actionData.attributes.mapped_count:moduleType.name==='category'?'ies':'s'}} {{actionData.attributes.mapped_count>1?'are':'is'}} mapped to <b>{{actionData.attributes.name}}</b>. <span *ngIf="trackers.length">Also <b>{{actionData.attributes.name}}</b> is linked to <b>{{trackers}}</b>
    {{actionData.attributes.linked_tracker.length>1?"trackers.":"tracker."}}</span> <span translate> Are you sure you want to delete <b>{{actionData.attributes.name}}</b> ?</span></p>
    </div>
    <div class="modal-footer">
       <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')" translate>Cancel</button>
       <button type="button" class="btn btn-primary btn-lg" (click)="[delHarmonisation(),modal.close('Close click')]" translate>Yes,
          Delete</button>
    </div>
 </ng-template>

 <ng-template #editHarmonisation let-modal>
    <div class="modal-header">
        <h4 class="modal-title" translate>Update Harmonised {{this.moduleType.name | titlecase}}</h4>
        <i class="icon icon-Close2 cursor-pointer" (click)="modal.dismiss('Cross click')">
        </i>
    </div>
    <div class="modal-body">
        <label class="mb-2" for="harmonisedName">Harmonised {{moduleType.name  |titlecase}} Name</label>
        <div class="input-container w-100">
            <input type="text" [formControl]="harmonisedName" name="harmonisedName" placeholder="Enter name"
            id="harmonisedName" class="w-100" [ngClass]="{ error: harmonisedName.invalid && (harmonisedName.dirty || harmonisedName.touched) }">
        <div class="errorLable" *ngIf="harmonisedName.invalid && (harmonisedName.dirty || harmonisedName.touched)">
            <span *ngIf="harmonisedName.errors.required" translate>Please enter {{moduleType.name}} name</span>
            <span *ngIf="harmonisedName.errors.fieldMessage" translate>{{harmonisedName.errors.fieldMessage}}</span>
        </div>
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')"
            translate>Cancel</button>
        <button type="button" class="btn btn-primary btn-lg"
            [disabled]="harmonisedName.invalid"
            (click)="[updateHarmonisationData(),harmonisedName.reset(),modal.close('Close click')]" translate>Update {{moduleType.name  |
            titlecase}}</button>
    </div>
</ng-template>