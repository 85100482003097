import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '@ds-common-services/utility-services/notification.service';
import { DataTransferService } from '@ds-shared/common-services/utility-services/data-transfer.service';
import { ModuleType } from '@ds-shared/models/common.model';
import { UpdateSegmentActionComponent } from '@ds-shared/common-components/actions/update-segment-action/update-segment-action.component';
import { ScorecardService } from '@ds-private-layouts/scorecard/scorecard.service';

@Component({
  selector: 'app-select-action-header',
  templateUrl: './select-action-header.component.html',
  styleUrls: ['./select-action-header.component.scss']
})
export class SelectActionHeaderComponent implements OnInit, OnDestroy {

  @Input() public data;
  @Input() public items;
  @Input() public gridInfo:ModuleType;
  @Output() bulkSelect = new EventEmitter();
  @Output() refreshGrid = new EventEmitter();
  private $destroy: Subject<boolean> = new Subject();
  public bulkSelectText: string = "Select All";
  constructor(private modalService: NgbModal, public router: Router, public scorecardService: ScorecardService, private route: ActivatedRoute, private notificationService: NotificationService, public dataTransferService: DataTransferService) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data?.currentValue) {
      if (this.items?.meta?.total_count === this.data?.size) this.bulkSelectText = 'Deselect All';
      else this.bulkSelectText = 'Select All'
    }
  }
  ngOnInit(): void {
  }
  openModal(content, view) {
    this.modalService.open(content, {
      centered: true,
      windowClass: "customModal",
    });
  }

  openStoreSegmentSelectionModal(){
    const modalRef = this.modalService.open(UpdateSegmentActionComponent, {
      centered: true,
      windowClass: "customModal",
      keyboard: false,
      backdrop: "static"
    });
    modalRef.componentInstance.data = this.data; 
    modalRef.result.then((result) => {
      if(!result) return;
      this.bulkSelect.emit(false);
      this.refreshGrid.emit(true);
    }, (reason) => {
    });
  }

  selectAllFunc() {
    this.bulkSelectText === 'Select All' ? this.bulkSelectText = 'Deselect All' : this.bulkSelectText = 'Select All';
    this.bulkSelect.emit(this.bulkSelectText !== 'Select All');
  }
  deleteProfile() {
    const profileType = this.route.snapshot["_urlSegment"]?.segments[1]?.path;
    const payload = {
      'action_on_all': this.data.size === 0,
      'profile_type': profileType,
    }
    if (this.data.size) payload["profiles"] = Array.from(this.data);
    this.scorecardService.bulkDelete('scorecard/profiles-delete', payload).pipe(takeUntil(this.$destroy)).subscribe((res: any) => {
      if (res) {
        this.dataTransferService.sendRefreshProfileGridRequest(true);
        this.notificationService.setMessage(
          res["context_code"],
          res["summary"]
        );
        this.bulkSelect.emit(false);
        this.dataTransferService.sendfireMenuCount('scorecard');
      }
    }, (err: any) => {
      this.notificationService.setMessage(
        err?.error?.context_code,
        err?.error?.summary
      );
    })
  }
  deleteFunc() {
    if ( ['store-scorecard','product-scorecard','brand-scorecard','keyword-scorecard', 'category-scorecard'].includes(this.gridInfo?.name) ) this.deleteProfile();
  }

  public deactivateProfile(): void {
    const data = {
      action_on_all: this.data.size === 0,
      is_active: false,
      profile_type: this.router.url.split('/').pop(),
    }
    if (this.data.size) data["profiles"] = Array.from(this.data);
    this.scorecardService.changeProfileStatus(data).pipe(takeUntil(this.$destroy)).subscribe((res: any) => {
      if (res) {
        this.notificationService.setMessage(
          res["context_code"],
          res["summary"]
        );
        this.modalService.dismissAll();
        this.refreshGrid.emit(true);
      }
    }, (err) => {
      this.notificationService.setMessage(
        err?.error?.context_code,
        err?.error?.summary
      );
    })
  }

  public ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
