import { SingleItemModel } from "./dashboard.model";

export interface CommonModel {
	context_code: number;
	data?: {} | [];
	summary: string;
	meta?: {
		current_page: number;
		next_page: number;
		prev_page: number;
		total_count: number;
		total_pages: number;
	};
}

//ng-select in-built search object
export interface SearchModel {
	term: string;
	items: SingleItemModel[];
}

//kpi common object
export interface KPIModel {
	name: string;
	code: string;
	tourAnchor?: string;
}

//Curreny Model
export interface CurrencyTemplate {
	id?: number,
	symbol: string;
	name: string;
	iso_code: string;
	delimeter: string;
	seperator: string;
}
export interface GridColumnModel {
	id: number;
	name: string;
	map: string;
	sort?: boolean;
	method: string;
	component?: string;
	key?: any;
	isChecked?: boolean;
	tourAnchor?: string;
}

export interface ItemObject {
	name: string;
	code?: string;
	id?: number;
}
export interface ModuleType {
	name: string;
	subtype?: string;
	type?: string;
	pagination?: {
		show?: boolean;
		maxSize?: number;
	};
	style?: {
		tableStyle?: CommonStyle;
		tableWrapper?: CommonStyle;
		noDataStyle?: CommonStyle;
		paginationStyle?: CommonStyle;
	};
	tabType?: string;
	bulkAction?: boolean;
	showHeader?: boolean;
	defaultSort?: { key: string, order: string, active: boolean };
}

export interface ColumnModel {
	id: number;
	name: string;
	map: string;
	meta?: any,
	sort: boolean;
	method: string;
	component: string;
	class?: string;
	prefix?: string;
	suffix?: string;
	hasRedirect: boolean;
	isChecked: boolean;
	style?: GridStyle;
	showCol?: boolean;
	isShowAction?: any;
	needExpander?: boolean
}

export interface GridStyle {
	th?: CommonStyle;
	td?: CommonStyle;
	"th-class"?: string;
	"td-class"?: string;
	'custom-th-class'?: string;
}
export interface CommonStyle {
	width?: string;
	"min-width"?: string;
	"max-width"?: string;
	height?: string;
	"min-height"?: string;
	"max-height"?: string;
	padding?: string;
	"padding-bottom"?: string;
	"padding-top"?: string;
	"padding-right"?: string;
	"padding-left"?: string;
	margin?: string;
	"background-color"?: string;
	color?: string;
	"font-size"?: string;
	border?: string;
	"border-top"?: string;
	"border-bottom"?: string;
	"border-right"?: string;
	"border-left"?: string;
	"border-radius"?: string;
	"text-align"?: string;
	"data-right"?: string;
	"margin-top"?: string;
	"display"?: string;
}
export interface CommonSearchModel {
	text: string;
	placeholder: string;
	style: CommonStyle;
}


export interface CommonPopUpModel {
	type: string;
	modelName: string;
	infoBanner: {
		name: string;
		subName: { text?: string, style?: CommonStyle, class?: string };
		value: string | number;
		subValue: { text?: string, style?: CommonStyle, class?: string };
		trackedBy?;
		segment?:string;
		showTrackBy?:boolean,
		harmonisedView?:boolean;
		hidepercVal?: boolean,
		meta?: any;
	};
	isDownloadAvailable?: boolean;
	gridInfo: {
		cols: ColumnModel[];
		path: string;
		tabDetails?:TabDetailsForPopupGrid;
		beforePath: string;
		params: any;
		customExportDetails?: {
			useCustomExport: boolean,
			exportFileName?: string,
			sendColList?: boolean
		};
		defaultSort?: { key: string, order: string, active: boolean };
	};
	callFromModule?:string;
	moduleRawData?:any;
	graphName?: string;
	exportAccess?: boolean; 
}

interface TabDetailsForPopupGrid{
	isTabNeeded: boolean,
	tabs:{id:number,code:string,display:string, hideFor?: string[]}[],
	selectedTab:{id:number, code:string, display: string},
	activeTabId: number
}
export interface CommonNgSelectModel {
	key: string,
	multiple: boolean,
	list: any[],
	selected: any,
	style: CommonStyle,
	disabled: boolean,
	placeholder: string,
	loading: boolean,
	search: string,
	path: string,
	meta?: CommonNgSelectPaginationModel,
	clear?: boolean,
	closeOnSelect?: boolean,
	bindLabel?: string
}
export interface CommonNgSelectPaginationModel {
	pageSize: number,
	pageNumber: number,
	initialList: any[],
	initialPageNumber: number
}
export interface globalSearchPayload {
	search_term: string,
    search_type: string,
    currency_id: string | number,
	search_id?: string | number,
	limit?: number
}

export interface SearchResult {
	id: number;
	type: string;
	attributes: {
	  name: string;
	  marketplace_name: string;
	  marketplace_id: number;
	  similarity: number;
	  is_substring: boolean;
	};
  }

export class CurrencyFormat{
	id: number;
	symbol: string;
	name: string;
	iso_code: string;
	delimeter: string;
	seperator: string;

	constructor(){
	this.id = null;
	this.symbol ='';
	this.name = '';
	this.iso_code = '';
	this.delimeter ='';
	this.seperator ='';
}
}

export class MiscellaneousTriggerDetails{
	moduleType: string;
	meta:{ [key: string]: any };
}

export class MarketplaceConfiguration{
	CONFIGS = {
		'likes': true,
		'percent_discount': true,
		'price_after_discount_max': true,
		'price_after_discount_min': true,
		'min_price': true,
		'max_price': true,
		'product_desc': true,
		'variant_count': true,
		'image_links': true,
		'quantities_available': true,
		'bundle_deals': true,
		'category_link_1': true,
		'category_link_2': true,
		'category_link_3': true,
		'number_of_stars': true,
		'number_of_ratings': true,
		'star_count_1': true,
		'star_count_2': true,
		'star_count_3': true,
		'star_count_4': true,
		'star_count_5': true,
		'quantities_sold': true,
		'quantities_sold_delta': true,
		'gmv': true,
		'is_verified': true,
		'number_of_favourites': true,
		'view_count': true,
		'shipping_fee': true,
		'num_vouchers': true,
		'product_url': true,
		'product_name': true,
		'product_url_hashcode': true,
		'other_sellers': true,
		'product_sku': true,
		'linked_to': true,
		'linked_to_hashcode': true,
		'parent_product_url': true,
		'parent_product_url_hashcode': true,
		'parent_product_ASIN': true,
		'seller_reg_number': true,
		'similar_products': true,
		'query_id_active': true,
		'query_id_discarded': true,
		'representative_name': true,
		'email': true,
		'location': true,
		'phone_number': true,
		'brand_url': true,
		'out_of_stock': true,
		'store_url': true,
		'store_followers': true,
	}
	isLikeFollowerAvailable: boolean = true;
	constructor(listOfMarketplaceConfiguration=[]){
		this.updateConfigs(listOfMarketplaceConfiguration);
	}

	private updateConfigs(marketplaceList=[]){
		for (const [key, value] of Object.entries(this.CONFIGS)) {
			this.CONFIGS[key] = marketplaceList.some(item=>item[key])
		}
		this.likeFollowerChecker();
	}

	private likeFollowerChecker(){
		this.isLikeFollowerAvailable = this.CONFIGS.likes && this.CONFIGS.store_followers
	}
}

export interface CommonTabList {
	id: number,
	name: string, 
	value: string
} 
export interface globalFilter {
		viewBy: string;
		weeklyQuartelyTimeline: any;
		marketPlace: any
}
export class InsightCardInfo {
	title: string;
	customerTitle: string;
	competitorTitle: string;
	type?: string
}
export interface CommonInsightModel {
	type: string;
	title: string;
	path: string;
	payload: any;
}

export interface ImageDownloadObj {
	isGraph?: boolean,
	graphName: string,
	mainDiv: string,
	legendDiv: string,
	style? : any
	onlyImage?: boolean,
}

export interface InnerGridDetailFormat {
	path: string,
	cols: ColumnModel[],
	params: {},
	exportDetails:{
		path:string,
		fileName:string,
		params:{}
	}
}
