<div class="login-container">
 <div class="login-row-container">
    <div class="login-first-row">
        <div class="login-support">
          <img height="3.75rem" width="8.875rem" src="../../../../assets/images/DS logo.svg" alt="" />
        </div>
        <div class="manage-login-text-1">
          <div class="login-first-heading">
            <p class="login-style" translate>Data insights to supercharge your eCommerce business</p>
          </div>
          <div class="manage-login-text-2">
            <div class="login-second-heading">
              <p class="login-style" translate>
                An all-in-one platform delivering personalized insights for top brands and retailers on their online merchandising performance.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="login-second-row-container">
        <div class="login-form">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="title-style">
              <label class="title-heading" translate>Welcome to Anchanto Digital Shelf</label>
            </div>
            <div class="title-style-format">
              <span class="title-details" translate>
                Login using your registered username and password
              </span>
            </div>

            <div class="input-container">
              <label for="default" translate>Username</label>
              <input type="email"
                id="email"
                formControlName="email_id"
                placeholder="{{'Enter Email' | translate}}"
                [ngClass]="{
                  error:
                    loginForm.controls['email_id'].invalid &&
                    (loginForm.controls['email_id'].dirty ||
                      loginForm.controls['email_id'].touched ||
                      submitted)
                }">
                <div class="errorLable" *ngIf="
                                  loginForm.controls['email_id'].invalid &&
                                  (loginForm.controls['email_id'].dirty ||
                                    loginForm.controls['email_id'].touched ||
                                    submitted)
                                ">
                  <i class="icon icon-Error"></i>
                  <span *ngIf="loginForm.controls['email_id'].errors.required" translate>Please Enter Username</span>
                  <span *ngIf="loginForm.controls['email_id'].errors"
                    translate>{{loginForm.controls['email_id'].errors['emailError']}}</span>
                </div>
            </div>
    
            <div class="input-container">
              <label for="default" translate>Password</label>
              <input autocomplete="off"
                  [type]="showPassword ? 'password' : 'text'"
                  id="password"
                  formControlName="password"
                  placeholder="{{'Enter Password' | translate}}"
                  [ngClass]="{
                    error:
                      loginForm.controls['password'].invalid &&
                      (loginForm.controls['password'].dirty ||
                        loginForm.controls['password'].touched ||
                        submitted)
                  }">
              <div class="errorLable" *ngIf="
                                loginForm.controls['password'].invalid &&
                                (loginForm.controls['password'].dirty ||
                                  loginForm.controls['password'].touched ||
                                  submitted)
                              ">
                <i class="icon icon-Error"></i>
                <span *ngIf="loginForm.controls['password'].errors.required" translate>Please Enter Password</span>
                <span *ngIf="loginForm.controls['password'].errors"
                  translate>{{loginForm.controls['password'].errors['passwordError']}}</span>
              </div>
              <span (click)="showLoginPassword()" [ngClass]="showPassword ? 'icon-Eye' : 'icon-Eye-closed'"
                class="icon display-password form-control-icon"></span>
            </div>
    
            <div class="login-third-row d-flex justify-content-between align-items-center">
              <span class="forgot-password" (click)="onForgotPassword()" translate>Forgot Password?</span>
              <button class="btn super-btn btn-lg" type="submit" [disabled]="isLoading" translate>Log In</button>
            </div>
          </form>
        </div>
      </div>
 </div>
</div>
<router-outlet></router-outlet>
