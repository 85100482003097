<div class="select-action-container d-flex align-items-center">
    <div class="selected-count d-flex align-items-center gap-8">
        <span class="count">{{bulkSelectText !== 'Select All' ? items?.meta?.total_count :data?.size}}</span>
        <span class="count-text">{{data?.size === 1 ? 'Tracker Selected' : "Trackers Selected"}}</span>
    </div>
        <div class="seperator"></div>
        <div class="d-flex align-items-center gap-6">
            <label class="checkbox" for="bulkSelect">
                <input type="checkbox" name="checkbox" id="bulkSelect" (click)="selectAllFunc()"
                    [checked]="bulkSelectText !== 'Select All'">
                <div class="checkbox-section"><i class="icon icon-Check"></i></div>
            </label>
            <div class="bulk-select cursor-pointer" (click)="selectAllFunc()" translate>Select All</div>
        </div>
    <div class="seperator"></div>
    <div ngbDropdown class="d-inline-block position-static" display="dynamic" #bulkSelect="ngbDropdown">
        <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"><span
                class="bulk-Delete cursor-pointer hover-state d-flex align-items-center gap-2"
                [ngClass]="{'activeAction':bulkSelect.isOpen()}">Actions <i class="icon icon-Caret-down"></i></span>
        </span>

        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" [ngClass]="{'d-none':!router.url.includes('scorecard') && !router.url.includes('share-of-search') }">
            <button class="dropdown-item" (click)="openModal(content,'deleteView')">
                <i class="icon icon-Trash"></i>
                <span>Delete {{bulkSelectText !== 'Select All' ? items?.meta?.total_count :data?.size}} {{data.size===1?'Tracker':'Trackers'}}</span>
            </button>
            <button class="dropdown-item" (click)="openModal(harvesting,'stopHarvesting')">
                <i class="icon icon-Pause"></i>
                <span translate>Pause {{data.size===1?'Tracker':'Trackers'}}</span>
            </button>
        </div>
        <!-- <div ngbDropdownMenu aria-labelledby="dropdownBasic1" *ngIf="router.url.includes('scorecard/store')">
            <button class="dropdown-item" (click)="openStoreSegmentSelectionModal()">
                <i class="icon icon-Chart-pie-alt"></i>
                <span translate>Update Segment</span>
            </button>
        </div> -->
    </div>
    <!-- <div class="bulk-Delete cursor-pointer hover-state d-flex align-items-center gap-2" (click)="openModal(content,'deleteView')" translate><i class="icon icon-Trash"></i>Delete All</div> -->
</div>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" translate>Delete {{'Tracker' | plural: data.size}}</h4>
    </div>
    <div class="modal-body">
        <i class="icon icon-Trash" ngbAutofocus></i>
        <p translate>Are you sure you want to delete <b>{{bulkSelectText !== 'Select All' ? items?.meta?.total_count
                :data?.size}} </b> <span translate>{{'tracker' | plural: data.size}} </span>?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')"
            translate>Cancel</button>
        <button type="button" class="btn btn-primary btn-lg" (click)="[deleteFunc(), modal.close('Close click')]"
            translate>Yes, Delete</button>
    </div>
</ng-template>

<ng-template #harvesting let-modal>
    <div class="modal-header">
        <h4 class="modal-title" translate>Pause {{'Tracker' | plural: data.size}}</h4>
    </div>
    <div class="modal-body">
        <i class="{{'icon icon-Pause'}}" ngbAutofocus></i>
        <p translate><span translate>Are you sure you want to pause tracking for</span><span> selected {{'tracker' |
                plural: data.size}}</span> ?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close(true)" translate>Cancel</button>
        <button type="button" class="btn btn-primary btn-lg" (click)="[deactivateProfile()]" translate>Yes</button>
    </div>
</ng-template>